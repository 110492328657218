import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme";
import React, { useState, useEffect } from "react";
import { useChainbridge } from "../Contexts/ChainbridgeContext";
import { ChilizConfigProvider } from '../config/configProvider'

interface IUrlsProps {
  privacyPolicyUrl?: string
  termsAndConditionsUrl?: string
}

const Urls = ({ privacyPolicyUrl, termsAndConditionsUrl }: IUrlsProps): JSX.Element => {
  const privacyPolicyUrlNotEmpty = privacyPolicyUrl !== undefined && privacyPolicyUrl.length > 0
  const termsAndConditionsUrlNotEmpty = termsAndConditionsUrl !== undefined && termsAndConditionsUrl.length > 0
  return (
    <>
      {privacyPolicyUrlNotEmpty && <a href={privacyPolicyUrl} target='_blank' rel="noreferrer" style={{ marginRight: '5px' }}>Privacy Policy</a>}
      {privacyPolicyUrlNotEmpty && termsAndConditionsUrlNotEmpty && '-'}
      {termsAndConditionsUrlNotEmpty && <a href={termsAndConditionsUrl} target='_blank' rel="noreferrer" style={{ marginLeft: '5px' }}>Terms & conditions</a>}
    </>
)}

const useStyles = makeStyles(
  ({ constants, palette, zIndex, breakpoints }: ITheme) => {
    return createStyles({
      footer: {
        bottom: 0,
        background: "#FFFFFF",
        width: '100%',
        padding: '20px 3.5%'
      },
    });
  }
);

export const AppFooter = (): JSX.Element => {
  const classes = useStyles();
  const { homeConfig } = useChainbridge();
  const [networkId, setNetworkId] = useState(0)

  useEffect(() => {
    setNetworkId(homeConfig?.networkId || 0)
  }, [homeConfig?.networkId])

  const config = new ChilizConfigProvider()
  let chainDetails:any
  
  if(networkId === 0 || undefined) 
    chainDetails = config.getDefaultChainDetails()

  if(networkId && networkId !== 0)
    chainDetails = config.getConfig(networkId)

  const { privacyPolicyUrl, termsAndConditionsUrl } = chainDetails
  const privacyPolicyUrlEmpty = privacyPolicyUrl !== undefined && privacyPolicyUrl.length === 0
  const termsAndConditionsUrlEmpty = termsAndConditionsUrl !== undefined && termsAndConditionsUrl.length === 0
  if (privacyPolicyUrlEmpty && termsAndConditionsUrlEmpty) {
    return <></>
  }
  return (
    <div className={classes.footer}>
      <Urls privacyPolicyUrl={privacyPolicyUrl} termsAndConditionsUrl={termsAndConditionsUrl}/>
    </div>
  )
}
