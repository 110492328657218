import * as React from "react";
import { ConnectButton as RainbowKitConnectButton } from "@rainbow-me/rainbowkit";
import { makeStyles, createStyles, ITheme } from "@chainsafe/common-theme";
import clsx from 'clsx';
import { Button, IButtonProps } from "./Button";

const useStyles = makeStyles(({ palette }: ITheme) =>
    createStyles({        
        account: {
            backgroundColor: '#f3f5f9',
            borderRadius: 0,
            color: palette.common.black.main,
            '&:hover': {
                color: '#FF0051',
                backgroundColor: '#f3f5f9'
            },
            '&:focus': {
                color: '#434343',
                backgroundColor: '#f3f5f9'
            },
            '&:active': {
                color: '#434343',
                backgroundColor: '#f3f5f9'
            }
        }
    })
);

type Props = {
    injectedClasses?: {
        connect?: string;
        wrongNetwork?: string;
        account?: string;
    }
} & IButtonProps;

export const ConnectButton = ({ className, injectedClasses, onClick, ...props }: Props) => {
    const classes = useStyles();

    return (
        <RainbowKitConnectButton.Custom>
            {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                mounted,
            }) => {
                const connected =
                    mounted &&
                    account &&
                    chain

                return (
                    <div {...(!mounted && {
                        'aria-hidden': true,
                        style: {
                          opacity: 0,
                          pointerEvents: 'none',
                          userSelect: 'none'
                        },
                      })}
                      {...props.fullsize ? { style: { width: '100%' } } : {}}
                      className={className}
                    >
                        {(() => {
                            if (!connected) {
                                return (
                                    <Button {...props} className={injectedClasses?.connect} onClick={(e) => {
                                        openConnectModal();
                                        onClick?.(e);
                                    }}>
                                        Connect
                                    </Button>
                                );
                            }

                            if (chain.unsupported) {
                                return (
                                    <Button {...props} className={injectedClasses?.wrongNetwork} onClick={(e) => {
                                        openChainModal();
                                        onClick?.(e);
                                    }}>
                                        Wrong network
                                    </Button>
                                );
                            }

                            return (
                                <Button {...props} className={clsx(classes.account, injectedClasses?.account)} onClick={(e) => {
                                    openAccountModal();
                                    onClick?.(e);
                                }}>
                                    {account.displayName}
                                </Button>
                            );
                        })()}
                    </div>
                );
            }}
        </RainbowKitConnectButton.Custom>
    )
}