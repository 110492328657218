import React from "react";
import { Bridge, BridgeFactory } from "@chainsafe/chainbridge-contracts";
import { useWeb3 } from "../../lib/web3-context";
import { BigNumber, ethers, utils } from "ethers";
import { BigNumber as BN } from "@ethersproject/bignumber";
import { useCallback, useEffect, useState } from "react";
import { EvmBridgeConfig, TokenConfig } from "../../chainbridgeConfig";
import { Erc20DetailedFactory } from "../../Contracts/Erc20DetailedFactory";
import { Weth } from "../../Contracts/Weth";
import { WethFactory } from "../../Contracts/WethFactory";
import { useNetworkManager } from "../NetworkManagerContext";
import {
  IDestinationBridgeProviderProps,
  IHomeBridgeProviderProps,
} from "./interfaces";
import { HomeBridgeContext } from "../HomeBridgeContext";
import { DestinationBridgeContext } from "../DestinationBridgeContext";
import { parseUnits } from "ethers/lib/utils";
import BRIDGE_ABI from "../../Contracts/abi/Bridge.json";

const resetAllowanceLogicFor = [
  "0xdac17f958d2ee523a2206206994597c13d831ec7", //USDT
  //Add other offending tokens here
];

const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const EVMHomeAdaptorProvider = ({
  children,
}: IHomeBridgeProviderProps) => {
  const {
    isReady,
    network,
    provider,
    gasPrice,
    address,
    tokens,
    wrapTokens,
    ethBalance
  } = useWeb3();

  const getNetworkName = (id: any) => {
    switch (Number(id)) {
      case 1:
        return "Mainnet";
      case 3:
        return "Ropsten";
      case 4:
        return "Rinkeby";
      case 5:
        return "Goerli";
      case 6:
        return "Kotti";
      case 42:
        return "Kovan";
      case 61:
        return "Ethereum Classic - Mainnet";
      case 1337:
        return "Chiliz Chain - Devnet";
      case 88881:
        return "Chiliz Chain - Integration";
      case 88882:
        return "Chiliz Chain - Spicy";
      case 88888:
        return "Chiliz Chain";
      default:
        return "Other";
    }
  };

  const {
    homeChainConfig,
    setTransactionStatus,
    setDepositNonce,
    handleSetHomeChain,
    homeChains,
    setNetworkId,
  } = useNetworkManager();

  const [homeBridge, setHomeBridge] = useState<Bridge | undefined>(undefined);
  const [bridgeFee, setBridgeFee] = useState<number | undefined>();

  const [depositAmount, setDepositAmount] = useState<number | undefined>();
  const [selectedToken, setSelectedToken] = useState<string>("");
  
  const [bridgePaused, setBridgePaused] = useState<boolean | undefined>();

  // Contracts
  const [wrapper, setWrapper] = useState<Weth | undefined>(undefined);
  const [wrapTokenConfig, setWrapperConfig] = useState<TokenConfig | undefined>(
    undefined
  );

  useEffect(() => {
    if (network) {
      const chain = homeChains.find((chain) => chain.networkId === network);
      setNetworkId(network);
      if (chain) {
        handleSetHomeChain(chain.chainId);
      }
    }
  }, [handleSetHomeChain, homeChains, network, setNetworkId]);

  useEffect(() => {
    const setupBridge = async () => {
      if (homeChainConfig && isReady && provider) {
        const signer = provider.getSigner();
        if (!signer) {
          console.log("No signer");
          return;
        }

        const bridge = BridgeFactory.connect(
          (homeChainConfig as EvmBridgeConfig).bridgeAddress,
          signer
        );
        setHomeBridge(bridge);
        
        const wrapperToken = homeChainConfig.wrapTokens.find(
          (token) => token.isNativeWrappedToken
        );

        if (!wrapperToken) {
          setWrapperConfig(undefined);
          setWrapper(undefined);
        } else {
          setWrapperConfig(wrapperToken);
          const connectedWeth = WethFactory.connect(
            wrapperToken.address,
            signer
          );
          setWrapper(connectedWeth);
        }
      }
    }

    void setupBridge();
  }, [
    homeChainConfig,
    isReady,
    provider
  ]);

  useEffect(() => {
    const getBridgeFee = async () => {
      if (homeBridge) {
        try {
          const bridgeFee = Number(utils.formatEther((await homeBridge._fee()).toString()));
          setBridgeFee(bridgeFee);
        } catch (e) {
        }
      }
    };
    getBridgeFee();
  }, [homeBridge]);

  useEffect(() => {
    const getBridgePaused = async () => {
      if (homeBridge) {
        try {
          const bridgePaused = await homeBridge.paused();
          setBridgePaused(bridgePaused);
        } catch (e) {

        }
      }
    };
    getBridgePaused();
  }, [homeBridge]);

  const depositEstimationFee = useCallback(async (
    recipient: string,
    tokenAddress: string,
    destinationChainId: number
) => {
    if (!provider || !ethBalance || !homeChainConfig || !homeChainConfig.bridgeAddress || !homeBridge) return ;
    
    const ethBalanceFactored = ethBalance / Number(process.env.REACT_APP_MAX_TX_FEE_SECURITY_FACTOR);
    const bridgeContract = new ethers.Contract(homeChainConfig.bridgeAddress, BRIDGE_ABI, provider);
    const signer = provider.getSigner();
    const contractWithSigner = bridgeContract.connect(signer);

    const token = homeChainConfig.tokens.find(
      (token) => {
        return token.address === tokenAddress;
      }
    );
    
    if (!token) {
      console.error("Invalid token selected");
      return ;
    }
    
    let tokenDecimals = token.decimals === undefined || token.decimals === null ? 18 : token.decimals;
    const parsedAmount = utils.parseUnits(ethBalanceFactored.toString(), tokenDecimals);
    let parsedFee = utils.parseUnits((bridgeFee || 0).toString(), 18);

    const valueToSend = parsedAmount;
    const aamountToEncode =  BN.from(parsedAmount.sub(parsedFee));

    const data =
        "0x" +
        utils
          .hexZeroPad(aamountToEncode.toHexString(), 32)
          .substr(2) + // Deposit Amount (32 bytes)
        utils
          .hexZeroPad(utils.hexlify((recipient.length - 2) / 2), 32)
          .substr(2) + // len(recipientAddress) (32 bytes)
        recipient.substr(2); // recipientAddress (?? bytes)

    let getGas
    let getGasPrice
    try {
      getGas = await contractWithSigner.estimateGas.deposit(destinationChainId, token.resourceId, data,  {value: valueToSend});
      getGasPrice = await provider.getGasPrice();
    } catch {
      return 
    }
    
    return ethers.utils.formatEther(getGas.mul(getGasPrice));
      
  },[bridgeFee, ethBalance, homeBridge, homeChainConfig, provider])

  const deposit = useCallback(
    async (
      amount: number,
      recipient: string,
      tokenAddress: string,
      destinationChainId: number
    ) => {
      if (!homeChainConfig || !homeBridge) {
        console.error("Home bridge contract is not instantiated");
        return;
      }
      const signer = provider?.getSigner();
      if (!address || !signer) {
        console.log("No signer");
        return;
      }

      const token = homeChainConfig.tokens.find(
        (token) => token.address === tokenAddress
      );

      if (!token) {
        console.log("Invalid token selected");
        return;
      }
      setTransactionStatus("Initializing Transfer");
      setDepositAmount(amount);
      setSelectedToken(tokenAddress);
      let tokenDecimals = token.decimals === undefined || token.decimals === null ? 18 : token.decimals;
      let parsedAmount = utils.parseUnits(amount.toString(), tokenDecimals);
      let parsedFee = utils.parseUnits((bridgeFee || 0).toString(), 18);
      let valueToSend = parsedFee;
      let amountToEncode = parsedAmount;
      if (tokenAddress !== ZERO_ADDRESS) {
        const erc20 = Erc20DetailedFactory.connect(tokenAddress, signer);
        tokenDecimals = tokens[tokenAddress].decimals;

        try {
          const currentAllowance = await erc20.allowance(
            address,
            (homeChainConfig as EvmBridgeConfig).erc20HandlerAddress
          );
          const formattedAllowance = Number(
            utils.formatUnits(currentAllowance.toString(), tokenDecimals)
          );

          if (formattedAllowance < amount) {
            if (
              formattedAllowance > 0 &&
              resetAllowanceLogicFor.includes(tokenAddress)
            ) {
              //We need to reset the user's allowance to 0 before we give them a new allowance
              //TODO Should we alert the user this is happening here?
              await (
                await erc20.approve(
                  (homeChainConfig as EvmBridgeConfig).erc20HandlerAddress,
                  BigNumber.from(utils.parseUnits("0", tokenDecimals))
                )
              ).wait(1);
            }
            await (
              await erc20.approve(
                (homeChainConfig as EvmBridgeConfig).erc20HandlerAddress,
                amountToEncode
              )
            ).wait(1);
          }
        } catch (error) {
          setTransactionStatus("Transfer Aborted");
          setSelectedToken(tokenAddress);
        }
      } else {
        valueToSend = BN.from(amountToEncode.add(parsedFee));
        amountToEncode = amountToEncode;
      }

      const data =
        "0x" +
        utils
          .hexZeroPad(amountToEncode.toHexString(), 32)
          .substr(2) + // Deposit Amount (32 bytes)
        utils
          .hexZeroPad(utils.hexlify((recipient.length - 2) / 2), 32)
          .substr(2) + // len(recipientAddress) (32 bytes)
        recipient.substr(2); // recipientAddress (?? bytes)

      try {
        homeBridge.once(
          homeBridge.filters.Deposit(
            destinationChainId,
            token.resourceId,
            null
          ),
          (destChainId, resourceId, depositNonce) => {
            setDepositNonce(`${depositNonce.toString()}`);
            setTransactionStatus("In Transit");
          }
        );
        await (
          await homeBridge.deposit(destinationChainId, token.resourceId, data, {
            value: valueToSend,
          })
        ).wait();

        return Promise.resolve();
      } catch (error) {
        setTransactionStatus("Transfer Aborted");
        setSelectedToken(tokenAddress);
      }
    },
    [
      homeBridge,
      address,
      bridgeFee,
      homeChainConfig,
      gasPrice,
      provider,
      setDepositNonce,
      setTransactionStatus,
      tokens
    ]
  );

  const wrapToken = async (value: number): Promise<string> => {
    if (!wrapTokenConfig || !wrapper?.deposit || !homeChainConfig)
      return "not ready";
    
    try {
      // Patch as wrapper functionality is not there
      const tx = await wrapper.deposit({
        value: parseUnits(`${value}`, homeChainConfig.tokens[0].decimals),
        gasPrice: gasPrice > 0
        ? gasPrice
        : BigNumber.from(
            utils.parseUnits(
              Number((homeChainConfig as EvmBridgeConfig).defaultGasPrice).toString(),
              9
            )
          ).toString()
      });

      await tx?.wait();
      if (tx?.hash) {
        return tx?.hash;
      } else {
        return "";
      }
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  const unwrapToken = async (value: number): Promise<string> => {
    if (!wrapTokenConfig || !wrapper?.withdraw || !homeChainConfig)
      return "not ready";

    try {
      const tx = await wrapper.withdraw(
        // Patch as wrapper functionality is not there
        BigNumber.from(parseUnits(`${value}`, homeChainConfig.tokens[0].decimals)),
        {
          gasPrice: gasPrice > 0
          ? gasPrice
          : BigNumber.from(
              utils.parseUnits(
                Number((homeChainConfig as EvmBridgeConfig).defaultGasPrice).toString(),
                9
              )
            ).toString()
        }
      );

      await tx?.wait();
      if (tx?.hash) {
        return tx?.hash;
      } else {
        return "";
      }
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  return (
    <HomeBridgeContext.Provider
      value={{
        connect: async () => Promise.reject('Connect not implemented'),
        disconnect: async () => {
          console.log('Disconnected')
        },
        getNetworkName,
        bridgeFee,
        depositEstimationFee,
        deposit,
        depositAmount,
        selectedToken,
        setDepositAmount,
        setSelectedToken,
        tokens,
        wrapTokens,
        wrapTokenConfig,
        wrapper,
        wrapToken,
        unwrapToken,
        isReady,
        chainConfig: homeChainConfig,
        address,
        nativeTokenBalance: ethBalance,
        bridgePaused,
      }}
    >
      {children}
    </HomeBridgeContext.Provider>
  );
};

export const EVMDestinationAdaptorProvider = ({
  children,
}: IDestinationBridgeProviderProps) => {
  const {
    depositNonce,
    destinationChainConfig,
    homeChainConfig,
    tokensDispatch,
    setTransactionStatus,
    setTransferTxHash,
    setDepositVotes,
    depositVotes,
  } = useNetworkManager();

  const [destinationBridge, setDestinationBridge] = useState<
    Bridge | undefined
  >(undefined);

  useEffect(() => {
    if (destinationBridge) return;
    let provider;
    if (destinationChainConfig?.rpcUrl.startsWith("wss")) {
      if (destinationChainConfig.rpcUrl.includes("infura")) {
        const parts = destinationChainConfig.rpcUrl.split("/");

        provider = new ethers.providers.InfuraWebSocketProvider(
          destinationChainConfig.networkId,
          parts[parts.length - 1]
        );
      }
      if (destinationChainConfig.rpcUrl.includes("alchemyapi")) {
        const parts = destinationChainConfig.rpcUrl.split("/");

        provider = new ethers.providers.AlchemyWebSocketProvider(
          destinationChainConfig.networkId,
          parts[parts.length - 1]
        );
      }
    } else {
      provider = new ethers.providers.JsonRpcProvider(
        destinationChainConfig?.rpcUrl
      );
    }
    if (destinationChainConfig && provider) {
      const bridge = BridgeFactory.connect(
        (destinationChainConfig as EvmBridgeConfig).bridgeAddress,
        provider
      );
      setDestinationBridge(bridge);
    }
  }, [destinationChainConfig, destinationBridge]);

  useEffect(() => {
    if (
      destinationChainConfig &&
      homeChainConfig?.chainId !== null &&
      homeChainConfig?.chainId !== undefined &&
      destinationBridge &&
      depositNonce
    ) {
      destinationBridge.on(
        destinationBridge.filters.ProposalEvent(
          homeChainConfig.chainId,
          BigNumber.from(depositNonce),
          null,
          null,
          null
        ),
        (originChainId, depositNonce, status, resourceId, dataHash, tx) => {
          switch (BigNumber.from(status).toNumber()) {
            case 1:
              tokensDispatch({
                type: "addMessage",
                payload: `Proposal created on ${destinationChainConfig.name}`,
              });
              break;
            case 2:
              tokensDispatch({
                type: "addMessage",
                payload: `Proposal has passed. Executing...`,
              });
              break;
            case 3:
              setTransactionStatus("Transfer Completed");
              setTransferTxHash(tx.transactionHash);
              break;
            case 4:
              setTransactionStatus("Transfer Aborted");
              setTransferTxHash(tx.transactionHash);
              break;
          }
        }
      );

      destinationBridge.on(
        destinationBridge.filters.ProposalVote(
          homeChainConfig.chainId,
          BigNumber.from(depositNonce),
          null,
          null
        ),
        async (originChainId, depositNonce, status, resourceId, tx) => {
          const txReceipt = await tx.getTransactionReceipt();
          if (txReceipt.status === 1) {
            setDepositVotes(depositVotes + 1);
          }
          tokensDispatch({
            type: "addMessage",
            payload: {
              address: String(txReceipt.from),
              signed: txReceipt.status === 1 ? "Confirmed" : "Rejected",
            },
          });
        }
      );
    }
    return () => {
      //@ts-ignore
      destinationBridge?.removeAllListeners();
    };
  }, [
    depositNonce,
    homeChainConfig,
    destinationBridge,
    depositVotes,
    destinationChainConfig,
    setDepositVotes,
    setTransactionStatus,
    setTransferTxHash,
    tokensDispatch,
  ]);

  return (
    <DestinationBridgeContext.Provider
      value={{
        disconnect: async () => {},
      }}
    >
      {children}
    </DestinationBridgeContext.Provider>
  );
};
