import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme";
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useChainbridge } from "../Contexts/ChainbridgeContext";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import CustomDrawer from "../Components/Custom/CustomDrawer";
import CustomDropDown from "../Components/Custom/CustomDropDown";
import hamburgerSVG from '../assets/icons/hamburger.svg';
import closeSVG from '../assets/icons/Close.svg';
import { ChilizConfigProvider } from '../config/configProvider'
import { IExternalLink } from '../chainbridgeConfig'
import { Typography } from "../lib/common-components";
import { ConnectButton } from "../Components/Custom/ConnectButton";
import { useAccount } from "wagmi";

const useStyles = makeStyles(
  ({ constants, palette, zIndex, breakpoints }: ITheme) => {
    return createStyles({
      root: {
        display: "flex",
        position: "fixed",
        justifyContent: "space-between",
        paddingLeft: "3.5%",
        width: "100%",
        top: 0,
        left: 0,
        color: palette.additional["header"][1],
        alignItems: "center",
        zIndex: zIndex?.layer2,
        background: "#FFFFFF"
      },
      menuOpen: {
        background: "#FBFBFB !important",
      },
      left: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center"
      },
      logo: {
        maxWidth: 500,
        "& svg, & img": {
          maxHeight: "100%",
          maxWidth: "100%",
        },
      },
      state: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingRight: '8%'
      },
      indicator: {
        display: "block",
        height: 10,
        width: 10,
        borderRadius: "50%",
        backgroundColor: palette.additional["green"][6],
        marginRight: constants.generalUnit,
      },
      address: {
        marginRight: constants.generalUnit,
      },
      network: {},
      accountInfo: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      },
      mainInfo: {
        display: "flex",
        flexDirection: "column",
      },
      header_content:{
        display: "flex",
        alignItems: "center"
      },
      header_content_mobile:{
        display: "flex",
        alignItems: "center",
        [breakpoints.down('sm')]: {
          display: "none"
        },
      },
      select: {
        border: '1px solid red'
      },
      header_dropdown:{
        marginRight: "30px",
        fontSize: "15px",
        [breakpoints.down('sm')]: {
          display: "none"
        }
      },
      header_walletConnect:{
        color: 'gray',
        fontWeight: "bold",
        fontSize: "18px",
        background: "#F3F5F9",
        height: "38px",
        padding: "10px",
        transform: "translateY(2px)",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        zIndex: -1,
        [breakpoints.down(300)]: {
          padding: '5px',
          width: '80px',
          fontSize: "10px",
        }
      },
      header_mobile_container:{
        display: "none",
        marginRight: "30px",
        [breakpoints.down('sm')]: {
          display: 'flex',
        },
        [breakpoints.down(300)]: {
        }
      },
      button_text:{
        fontStyle: 'PP Mori',
        fontSize: '14px',
        color: '#434343',
        [breakpoints.down(300)]: {
          fontSize: '9px',
          marginLeft: '14px'
        }
      },
      mobileMenu_close:{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      },
      mobileMenu: {
        marginTop: '75px'
      },
      backdrop: {
        "&.open": {
          top: 75,
          zIndex: 0
        }
      },
      wrap_content:{
        marginRight: 18,
        color: '#000',
        "& p": {
          fontWeigth: 600,
          fontSize: '15px',
        },
        "& a": {
          textDecoration: 'none'
        },
        "&:hover":{
          cursor: 'pointer'
        }
      },
      noWalletConnected: {
        backgroundColor: '#f3f5f9',
        color: palette.common.black.main,
        padding: '10px',
        fontSize: '14px',
        lineHeight: '22px'
      }
      // mobileMenu_items: { 
      //   marginTop: "40px",
      //   fontSize: "16px",
      //   fontWeight: 600,
      //   padding: "10px 2px",
      //   cursor: "pointer",
      //   "&:hover": {
      //     transition: "ease-in-out",
      //     textDecoration: "underline",
      //     transitionDuration: "4s",
      //   }
      // }
    });
  }
);

const MobileMenuItems = ({items}: any) => {
  items.unshift(
    {
      label: (
        <a rel="noopener noreferrer" style={{ "textDecoration": "none" }} href={'./transfer'}>
          Transfer
        </a>
      ),
      key: `external-transfer`
    },
    {
      label: (
        <a rel="noopener noreferrer" style={{ "textDecoration": "none" }} href={'./wrap'}>
          Wrap
        </a>
      ),
      key: `external-wrap`
    }
  )

  return(
    <div>
      <div>
        {items?.map((item: any, i: any) => (
          <p key={i} style={{ padding: "15px 0px", textDecoration: 'none'}}>{item.label}</p>
        ))}
      </div>
    </div>
  );
}

interface IAppHeader {}

const getExternalLinkMenuItems = (externalLinks?: IExternalLink[]): any[] => {
  if (typeof externalLinks === 'undefined') {
    return []
  }
  const menuItems: any[] = []
  for (const externalLink of externalLinks) {
    const { name, url } = externalLink
    menuItems.push({
      label: (
        <a target="_blank" rel="noopener noreferrer" style={{ "textDecoration": "none" }} href={url}>
          {name}
        </a>
      ),
      key: `external-${menuItems.length}`
    })
  }
  return menuItems
}

const AppHeader: React.FC<IAppHeader> = () => {
  const classes = useStyles();
  const { homeConfig } = useChainbridge();
    
  const [networkId, setNetworkId] = useState(0)
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const { isConnected } = useAccount()
  
  useEffect(() => {
    setNetworkId(homeConfig?.networkId || 0)
  }, [homeConfig?.networkId])

  const config = new ChilizConfigProvider()
  let chainDetails:any
  
  if(networkId === 0 || undefined) 
    chainDetails = config.getDefaultChainDetails()

  if(networkId && networkId !== 0)
    chainDetails = config.getConfig(networkId)

  const items = getExternalLinkMenuItems(chainDetails.externalLinks)
  
  return (
    <header className={clsx(classes.root, showMobileMenu ? classes.menuOpen : "") }>
      <div className={classes.left}>
        <div className={classes.header_mobile_container}>
          <div onClick={() => {setShowMobileMenu(!showMobileMenu)}}><img src={!showMobileMenu ? hamburgerSVG : closeSVG} alt="menuLogo" style={{cursor: "pointer"}}/></div>
          <CustomDrawer position="top" children={<MobileMenuItems items={items}/> } open={showMobileMenu} className={classes.mobileMenu} classNames={{backdrop: classes.backdrop}} backdrop={false} />
        </div>
        <div className={classes.logo}>
          <Logo style={{ height: '75px', padding: 8 }} />
        </div>
      </div>
      <section className={classes.state}>
        <div className={classes.header_content}>
          <div className={classes.header_content_mobile}>
            <div className={classes.wrap_content}>
              <Typography component="p" variant="h5"><a href="/transfer">Transfer</a></Typography>
            </div>
            <div className={classes.wrap_content}>
              <Typography component="p" variant="h5"><a href="/wrap">Wrap CHZ</a></Typography>
            </div>
          </div>
          <div className={classes.header_dropdown}>
            <CustomDropDown 
              chainId={networkId}
            />
          </div>
          {isConnected ? <ConnectButton /> : <p className={classes.noWalletConnected}>No Wallet Connected</p>}
        </div>
      </section>
    </header>
  );
};

export default AppHeader;
