import * as React from "react";
import { useState, useEffect, useReducer } from "react";
import { providers, ethers, utils } from "ethers";
import { BigNumber } from "@ethersproject/bignumber";
import { BigNumber as BN } from "bignumber.js";
import { Erc20DetailedFactory } from "../interfaces/Erc20DetailedFactory";
import { Erc20Detailed } from "../interfaces/Erc20Detailed";
import { TokenInfo, Tokens, tokensReducer } from "./tokensReducer";
import { useAccount, useBalance, useNetwork, useWalletClient } from "wagmi";
import networkConfig from '../../../../config/networks-config.json';
import { Chain, Client, Hex, Account, Transport } from "viem";

type EthGasStationSettings = "fast" | "fastest" | "safeLow" | "average";
type EtherchainGasSettings = "safeLow" | "standard" | "fast" | "fastest";

type TokenConfig = {
  address: string;
  name?: string;
  symbol?: string;
  imageUri?: string;
  decimals: number
};

type TokensToWatch = {
  [networkId: number]: TokenConfig[];
};

type Web3ContextProps = {
  children: React.ReactNode;
  ethGasStationApiKey?: string;
  gasPricePollingInterval?: number; //Seconds between gas price polls. Defaults to 0 - Disabled
  gasPriceSetting?: EthGasStationSettings | EtherchainGasSettings;
  spenderAddress?: string;
  tokensToWatch?: TokensToWatch; // Network-keyed collection of token addresses to watch
  wrapTokensToWatch?: TokensToWatch
};

type TWeb3Context = {
  address?: string;
  ethBalance?: number;
  gasPrice: number;
  isReady: boolean;
  network?: number;
  provider?: providers.Web3Provider;
  tokens: Tokens;
  wrapTokens: Tokens,
  refreshGasPrice(): Promise<void>;
  signMessage(message: string): Promise<string>;
};

const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

const Web3Context = React.createContext<TWeb3Context | undefined>(undefined);

export function clientToProvider(client: Client<Transport, Chain, Account>) {
  const { chain, transport } = client

  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }

  return new providers.Web3Provider(transport, network)
}

const Web3Provider = ({
  children,
  ethGasStationApiKey,
  gasPricePollingInterval = 0,
  gasPriceSetting = "fast",
  tokensToWatch,
  wrapTokensToWatch,
  spenderAddress
}: Web3ContextProps) => {
  const [provider, setProvider] = useState<providers.Web3Provider | undefined>(
    undefined
  );
  const [tokens, tokensDispatch] = useReducer(tokensReducer, {});
  const [wrapTokens, wrapTokensDispatch] = useReducer(tokensReducer, {});
  const [gasPrice, setGasPrice] = useState(0);

  const { address } = useAccount();
  const { chain } = useNetwork();
  const { data: client } = useWalletClient({ chainId: chain?.id });
  const tokenAddress = networkConfig.chainConfigs.find((c) => c.networkId === chain?.id)?.tokens.find(x => x.symbol.toLowerCase() === 'chz')?.address as Hex;
  const { data: chzBalance, isSuccess: isBalanceFetchedSuccessfully } = useBalance({
    address,
    chainId: chain?.id,
    watch: true,
    // If we're on a CC network, we want to fetch the native currency balance
    ...(Math.floor((chain?.id ?? 0) / 10) === 8888 ? {} : {
      token: tokenAddress,
      enabled: !!tokenAddress
    })
  });

  const isReady = Boolean(provider && address && isBalanceFetchedSuccessfully);

  useEffect(() => {
    if (client) {
      setProvider(clientToProvider(client))
    }
  }, [client]);

  // Gas Price poller
  // Gas Price fetcher for the CC2 networks
  const fetchGasPrice = async () => {
    try {
      const currentGasPrice = await provider?.getGasPrice();
      if (currentGasPrice) {
        setGasPrice(Number(currentGasPrice.toString()));
      } else {
        setGasPrice(0)
      }
    } catch (error) {
      console.error('Error fetching gas price:', error);
    }
  };

  useEffect(() => {
    let poller: NodeJS.Timeout;
    if (chain?.id === 1 && gasPricePollingInterval > 0) {
      refreshGasPrice();
      poller = setInterval(refreshGasPrice, gasPricePollingInterval * 1000);
    } else {
      fetchGasPrice()
    }
    return () => {
      if (poller) {
        clearInterval(poller);
      }
    };
  }, [chain?.id]);

  // Token balance and allowance listener
  // TODO: Allowance check not needed unless target is specificed
  useEffect(() => {
    const checkBalanceAndAllowance = async (
      token: Erc20Detailed,
      decimals: number,
      isWrap: boolean
    ) => {
      if (address) {
        const bal = await token.balanceOf(address);
        const tokenBalance = Number(utils.formatUnits(BigNumber.from(bal), decimals));
        const balanceBN = new BN(bal.toString()).shiftedBy(-decimals);
        let spenderAllowance = 0;
        if (spenderAddress) {
          spenderAllowance = Number(
            utils.formatUnits(
              BigNumber.from(await token.balanceOf(address)),
              decimals
            )
          );
        }

        if (isWrap) {
          wrapTokensDispatch({
            type: "updateTokenBalanceAllowance",
            payload: {
              id: token.address,
              spenderAllowance: spenderAllowance,
              balance: tokenBalance,
              balanceBN,
            },
          });
        } else {
          tokensDispatch({
            type: "updateTokenBalanceAllowance",
            payload: {
              id: token.address,
              spenderAllowance: spenderAllowance,
              balance: tokenBalance,
              balanceBN,
            },
          });
        }
      }
    };

    provider?.getNetwork().then((networkInfo) => {
      const network = networkInfo.chainId;
      const networkTokens =
        (tokensToWatch && network && tokensToWatch[network]) || [];
      const networkWrapTokens =
        (wrapTokensToWatch && network && wrapTokensToWatch[network]) || [];

      let tokenContracts: Array<Erc20Detailed> = [];
      let wrapTokenContracts: Array<Erc20Detailed> = [];

      if (address && networkTokens.length > 0) {
        tokensDispatch({ type: "resetTokens" });
        wrapTokensDispatch({ type: "resetTokens" });

        const initializeToken = async (
          tokenDetails: TokenConfig,
          isWrap: boolean,
          dispatch: React.Dispatch<any>
        ) => {
          const signer = await provider.getSigner();
          const newTokenInfo: TokenInfo = {
            decimals: 0,
            balance: 0,
            balanceBN: new BN(0),
            imageUri: tokenDetails.imageUri,
            name: tokenDetails.name,
            symbol: tokenDetails.symbol,
            spenderAllowance: 0,
          };
        
          if (tokenDetails.address !== ZERO_ADDRESS) {
            const tokenContract = Erc20DetailedFactory.connect(
              tokenDetails.address,
              signer
            );
        
            newTokenInfo.allowance = tokenContract.allowance;
            newTokenInfo.approve = tokenContract.approve;
            newTokenInfo.transfer = tokenContract.transfer;
        
            try {
              if (!tokenDetails.name) {
                const tokenName = await tokenContract.name();
                newTokenInfo.name = tokenName;
              }
              if (!tokenDetails.symbol) {
                const tokenSymbol = await tokenContract.symbol();
                newTokenInfo.symbol = tokenSymbol;
              }
              const tokenDecimals = await tokenContract.decimals();
              newTokenInfo.decimals = tokenDecimals;
            } catch (error) {
              console.error(
                `Error getting token details for ${isWrap ? 'wrap' : 'regular'} token: ${error}`
              );
            }
        
            checkBalanceAndAllowance(
              tokenContract,
              newTokenInfo.decimals,
              isWrap
            );
        
            const filterTokenApproval = tokenContract.filters.Approval(
              address,
              null,
              null
            );
            const filterTokenTransferFrom = tokenContract.filters.Transfer(
              address,
              null,
              null
            );
            const filterTokenTransferTo = tokenContract.filters.Transfer(
              null,
              address,
              null
            );
        
            tokenContract.on(filterTokenApproval, () =>
              checkBalanceAndAllowance(
                tokenContract,
                newTokenInfo.decimals,
                isWrap
              )
            );
            tokenContract.on(filterTokenTransferFrom, () =>
              checkBalanceAndAllowance(
                tokenContract,
                newTokenInfo.decimals,
                isWrap
              )
            );
            tokenContract.on(filterTokenTransferTo, () =>
              checkBalanceAndAllowance(
                tokenContract,
                newTokenInfo.decimals,
                isWrap
              )
            );
        
            if (isWrap) {
              wrapTokenContracts.push(tokenContract);
            } else {
              tokenContracts.push(tokenContract);
            }
          } else {
            newTokenInfo.decimals = 18;
            newTokenInfo.balance = Number(chzBalance?.formatted);
          }
        
          dispatch({
            type: "addToken",
            payload: { id: tokenDetails.address, token: newTokenInfo },
          });
        };
        
        networkTokens.forEach(async (token) => {
          await initializeToken(token, false, tokensDispatch);
        });

        networkWrapTokens.forEach(async (wrapToken) => {
          await initializeToken(wrapToken, true, wrapTokensDispatch);
        });
      }
      return () => {
        if (wrapTokenContracts.length > 0) {
          wrapTokenContracts.forEach((tc) => {
            tc.removeAllListeners();
          });
          wrapTokenContracts = [];
          wrapTokensDispatch({ type: "resetTokens" });
        }

        if (tokenContracts.length > 0) {
          tokenContracts.forEach((tc) => {
            tc.removeAllListeners();
          });
          tokenContracts = [];
          tokensDispatch({ type: "resetTokens" });
        }
      };
    });
  }, [provider, address, chzBalance]);

  const signMessage = async (message: string) => {
    if (!provider) return Promise.reject("The provider is not yet initialized");

    const data = ethers.utils.toUtf8Bytes(message);
    const signer = await provider.getSigner();
    const addr = await signer.getAddress();
    const sig = await provider.send("personal_sign", [
      ethers.utils.hexlify(data),
      addr.toLowerCase(),
    ]);
    return sig;
  };

  const refreshGasPrice = async () => {
    try {
      let gasPrice;
      if (ethGasStationApiKey) {
        const ethGasStationResponse = await (
          await fetch(
            `https://ethgasstation.info/api/ethgasAPI.json?api-key=${ethGasStationApiKey}`
          )
        ).json();
        gasPrice = ethGasStationResponse[gasPriceSetting] / 10;
      } else {
        const etherchainResponse = await (
          await fetch("https://www.etherchain.org/api/gasPriceOracle")
        ).json();
        gasPrice = Number(etherchainResponse[gasPriceSetting]);
      }

      const newGasPrice = !isNaN(Number(gasPrice)) ? Number(gasPrice) : 65;
      setGasPrice(newGasPrice);
    } catch (error) {
      console.log(error);
      console.log("Using 65 gwei as default");
      setGasPrice(65);
    }
  };


  return (
    <Web3Context.Provider
      value={{
        address,
        provider,
        network: chain?.id,
        ethBalance: Number(chzBalance?.formatted),
        isReady,
        gasPrice,
        refreshGasPrice,
        tokens: tokens,
        wrapTokens: wrapTokens,
        signMessage
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

const useWeb3 = () => {
  const context = React.useContext(Web3Context);
  if (context === undefined) {
    throw new Error("useOnboard must be used within a OnboardProvider");
  }
  return context;
};

export { Web3Provider, useWeb3 };
