import axios from 'axios';
import { ChilizConfigProvider } from '../config/configProvider'
import { useEffect, useState } from 'react'

export const useDynamicSetting = <T = unknown>({ chainId, key, defaultValue }: { chainId: number, key: string; defaultValue?: T }) => {
	const [dynamicSetting, setDynamicSetting] = useState<T | null>(null)
	const config = new ChilizConfigProvider()
	const { getConfig, getDefaultChainDetails } = config;
    const chainDetails = chainId ? getConfig(chainId) : getDefaultChainDetails()


	useEffect(() => {
		const fetchDynamicSetting = async () => {
			try {
				const { data } = await axios.get(`${chainDetails.backendUrl}dynamic-settings/${key}`)
				const value = data?.[key]

				try {
					const parsed = JSON.parse(value)

					setDynamicSetting(parsed)
				} catch {
					setDynamicSetting(typeof value === 'string' ? (value as T) : defaultValue ? defaultValue : null)

					if (value === undefined) {
						console.warn(`${key} setting not found.`)
					}
				}
			} catch {
				console.error('Failed to fetch dynamic setting.')
				setDynamicSetting(defaultValue ?? null)
			}
		}

		fetchDynamicSetting()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainDetails.backendUrl, key])

	return dynamicSetting
}
