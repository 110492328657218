import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from './reportWebVitals'
import ReactGA from 'react-ga4'

if (process.env.REACT_APP_TRACKING_ID !== undefined && process.env.REACT_APP_TRACKING_ID !== '') {
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID ?? '')
}

ReactDOM.render(<App />, document.getElementById("root"));

const SendAnalytics = (): void => {
    ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname
    })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
