import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, ITheme } from "@chainsafe/common-theme";
import AboutDrawer from "../../Modules/AboutDrawer";
import ChangeNetworkDrawer from "../../Modules/ChangeNetworkDrawer";
import {
  Button,
  Typography,
  QuestionCircleSvg,
} from "../../lib/common-components";
import TokenSelectInput from "../Custom/TokenSelectInput";
import { Form, Formik } from "formik";
import clsx from "clsx";
import { useChainbridge } from "../../Contexts/ChainbridgeContext";
import { object, string } from "yup";
import { TokenConfig } from "../../chainbridgeConfig";
import PreflightModalWrap from "../../Modules/PreflightModalWrap";
import WrapActiveModal from "../../Modules/WrapActiveModal";
import { forwardTo } from "../../Utils/History";
import { ROUTE_LINKS } from "../Routes";
import SimpleTokenInput from "../Custom/SimpleTokenInput";
import { useNetworkManager } from "../../Contexts/NetworkManagerContext";
import NetworkUnsupportedModal from "../../Modules/NetworkUnsupportedModal";
import { useCookie3 } from '../Custom/Cookie3Provider'
import { useChainModal} from '@rainbow-me/rainbowkit';
import { ConnectButton } from "../Custom/ConnectButton";

const useStyles = makeStyles(({ constants, palette }: ITheme) =>
  createStyles({
    root: {
      borderRadius: "12px",
      padding: "24px",
      minHeight: constants.generalUnit * 60,
      backgroundColor: "#FFFFFF",
      position: "relative",
      overflow: '-moz-scrollbars-none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': { display: 'none' },
    },
    walletArea: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    blurb: {
      color: palette.common.black.main,
      fontSize: "15px",
      lineHeight: "20px",
    },
    connectButton: {
      margin: `${constants.generalUnit * 3}px 0 ${constants.generalUnit * 6}px`
    },
    connecting: {
      textAlign: "center",
      marginBottom: constants.generalUnit * 2,
    },
    connected: {
      width: "100%",
      "& > *:first-child": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      },
    },
    changeButton: {
      cursor: "pointer",
      color: "#FF0051",
      textDecoration: "underline",
      fontSize: "10px"
    },
    networkName: {
      padding: `${constants.generalUnit * 1}px ${
        constants.generalUnit * 1.5
      }px`,
      border: `1px solid ${palette.additional["gray"][6]}`,
      borderRadius: 8,
      color: palette.additional["gray"][9],
      marginTop: constants.generalUnit,
      marginBottom: constants.generalUnit * 2,
      fontWeight: 700,
      fontSize: "12px",
      display: 'flex',
      justifyContent: 'space-between'
    },
    formArea: {
      "&.disabled": {
        opacity: 0.4,
      },
    },
    currencySection: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
      margin: `${constants.generalUnit * 3}px 0`,
    },
    tokenSelect:{
      width: "100%"
    },
    tokenInputContainer:{
      width: "100%",
      height: "100%",
      marginLeft: 20,
    },
    tokenInputArea: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-around",
      // paddingRight: constants.generalUnit,
      width: "100%",
      height: "100%"
    },
    tokenInput: {
      width: "100%",
      margin: 0,
      "& > div": {
        height: 42,
        "& input": {
          height: 42,
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          borderTopRightRadius: 5,
        },
      },
      "& span:last-child.error": {
        position: "absolute",
      },
    },
    maxButton: {
      height: 32,
      color: '#FF0051',
      fontWeight: 600,
      border: 'none',
      backgroundColor: '#fff',
      "&:hover": {
        color: '#C90E44',
      },
      "&:focus": {
        color: "#FF0051"
      }
    },
    tokenIndicator: {
      // width: 120,
      textAlign: "right",
      "& p": {
        marginBottom: constants.generalUnit,
      },
      "& *": {
        cursor: "pointer",
      },
    },
    generalInput: {
      "& > span": {
        marginBottom: constants.generalUnit,
      },
    },
    faqButton: {
      cursor: "pointer",
      height: 21,
      width: 21,
      marginTop: constants.generalUnit * 5,
      fill: `#FF0051 !important`,
      background: "#EDEDED",
      borderRadius: "50%",
    },
    token: {
      backgroundColor: palette.additional["gray"][1],
      borderRadius: 2,
      border: `1px solid ${palette.additional["gray"][6]}`,
      padding: `${constants.generalUnit * 1}px ${
        constants.generalUnit * 1.5
      }px`,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
      height: constants.generalUnit * 4,
      "& img, & svg": {
        display: "block",
        height: 14,
        width: 14,
        marginLeft: 10,
      },
      "& span": {
        minWidth: `calc(100% - 30px)`,
        textAlign: "right",
        color: palette.additional["gray"][9],
      },
    },
    tokenItem: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
      "& img, & svg": {
        display: "block",
        height: 14,
        width: 14,
        marginRight: 10,
      },
      "& span": {
        minWidth: `calc(100% - 30px)`,
        textAlign: "right",
      },
    },
    submitButtonArea:{},
    wrapButton: {
      background: '#FF0051',
      borderRadius: "8px",
      "&:hover": {
        background: '#C90E44'
      }
    }
  })
);

type PreflightDetails = {
  tokenAmount: number;
};

const MainPage = () => {
  const classes = useStyles();
  const { walletType } = useNetworkManager();
  const {
    wrapTokenConfig,
    wrapToken,
    unwrapToken,
    homeConfig,
    isReady,
    wrapTokens,
    nativeTokenBalance,
    address,
  } = useChainbridge();

  const { openChainModal } = useChainModal()

  const cookie3 = useCookie3()
  useEffect(() => {
    void cookie3?.trackPageView({documentTitle: 'Wrap'})
  }, [cookie3])

  const [aboutOpen, setAboutOpen] = useState<boolean>(false);
  const [walletConnecting, setWalletConnecting] = useState(false);
  const [preflightModalOpen, setPreflightModalOpen] = useState<boolean>(false);
  const [preflightDetails, setPreflightDetails] = useState<PreflightDetails>({
    tokenAmount: 0,
  });
  const [action, setAction] = useState<"wrap" | "unwrap">("wrap");

  const [txDetails, setTxDetails] = useState<
    | {
        txState?: "inProgress" | "done";
        value: number;
        tokenInfo: TokenConfig;
        txHash?: string;
        action: "wrap" | "unwrap";
      }
    | undefined
  >(undefined);

  useEffect(() => {
    if (walletType !== "select" && walletConnecting === true) {
      setWalletConnecting(false);
    } else if (walletType === "select") {
      setWalletConnecting(true);
    }
  }, [walletType, walletConnecting]);

  const handleWrapToken = async () => {
    if (!wrapTokenConfig || !wrapToken || !homeConfig) return;
    
    try {
      setTxDetails({
        tokenInfo: wrapTokenConfig,
        value: preflightDetails.tokenAmount,
        txState: "inProgress",
        action: action,
      });
      const txHash = await wrapToken(preflightDetails.tokenAmount);

      if (txHash === "") {
        setTxDetails(undefined);
        throw Error("Wrap Transaction failed");
      }

      setTxDetails({
        tokenInfo: wrapTokenConfig,
        value: preflightDetails.tokenAmount,
        txHash: txHash,
        txState: "done",
        action: action,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleUnwrapToken = async () => {
    if (!wrapTokenConfig || !unwrapToken || !homeConfig) return;

    try {
      setTxDetails({
        tokenInfo: wrapTokenConfig,
        value: preflightDetails.tokenAmount,
        txState: "inProgress",
        action: action,
      });

      const txHash = await unwrapToken(preflightDetails.tokenAmount);

      if (txHash === "") {
        setTxDetails(undefined);
        throw Error("Unwrap Transaction failed");
      }

      setTxDetails({
        tokenInfo: wrapTokenConfig,
        value: preflightDetails.tokenAmount,
        txHash: txHash,
        txState: "done",
        action: action,
      });
    } catch (error) {
      console.error(error);
    }
  };
  
  // Patch as wrapper functionality is not there
  const REGEX = 18 && 18 > 0
      ? new RegExp(`^[0-9]{1,18}(.[0-9]{1,18})?$`)
      : new RegExp(`^[0-9]{1,18}?$`);

  const wrapSchema = object().shape({
    tokenAmount: string()
      .matches(REGEX, "Input invalid")
      .test("Min", "Less than minimum", (value) => {
        if (value) {
          return parseFloat(value) > 0;
        }
        return false;
      })
      .test("Max", "Insufficent funds", (value) => {
        return action === "wrap"
          ? nativeTokenBalance &&
            value &&
            parseFloat(value) <= nativeTokenBalance
            ? true
            : false
          : wrapTokens[wrapTokenConfig?.address || "0x"].balance &&
            value &&
            parseFloat(value) <=
            wrapTokens[wrapTokenConfig?.address || "0x"]?.balance
          ? true
          : false;
      })
      .required("Please set a value"),
  });
  return (
    <article className={classes.root}>
      <div className={classes.walletArea}>
        {!isReady ? (
          <>
            <Typography className={classes.blurb} component="p" variant="h5">
              To convert a token that needs to be wrapped, please connect to the
              network that the token exists natively for. For example, to
              convert CHZ into wrapped CHZ (wCHZ), your wallet must be connected
              to the Chiliz Chain.
            </Typography>
            <ConnectButton
              fullsize
              injectedClasses={{
                connect: classes.connectButton,
              }}
            />
          </>
        ) : (
          <section className={classes.connected}>
            <Typography component="p" variant="body1">Network</Typography>
            <Typography
              component="h2"
              variant="h5"
              className={classes.networkName}
            >
              {homeConfig?.name}
              <div>
              <Typography
                className={classes.changeButton}
                variant="body1"
                onClick={() => openChainModal?.()}
              >
                Change Network
              </Typography>
            </div>
            </Typography>
          </section>
        )}
      </div>
      <Formik
        initialValues={{
          tokenAmount: 0,
        }}
        validationSchema={wrapSchema}
        validateOnChange={false}
        onSubmit={(values) => {
          setPreflightDetails({
            ...values,
          });
          setPreflightModalOpen(true);
        }}
      >
        <Form
          className={clsx(classes.formArea, {
            disabled: !homeConfig,
          })}
        >
          <section className={classes.currencySection}>
            <div className={classes.tokenSelect}>
              <section>       
                <TokenSelectInput
                  tokens={wrapTokens}
                  name="token"
                  label={`Balance: `}
                  className={classes.generalInput}
                  placeholder=""
                  sync={(tokenAddress) => {
                    if(tokenAddress === '0x0000000000000000000000000000000000000000'){
                      setAction('wrap')
                    } else {
                      setAction('unwrap')
                    }
                  }}                 
                  options={
                    Object.keys(wrapTokens).map((t) => ({
                      value: t,
                      label: (
                        <div className={classes.tokenItem}>
                          {wrapTokens[t]?.imageUri && (
                            <img
                              src={wrapTokens[t]?.imageUri}
                              alt={wrapTokens[t]?.symbol}
                            />
                          )}
                          <span>{wrapTokens[t]?.symbol || t}</span>
                        </div>
                      ),
                    })) || []
                  }
                />
              </section>
            </div>
            <section className={classes.tokenInputContainer}>
              <div
                className={clsx(classes.tokenInputArea, classes.generalInput)}
              >
                <SimpleTokenInput
                  classNames={{
                    input: clsx(classes.tokenInput, classes.generalInput),
                    button: classes.maxButton,
                  }}
                  name="tokenAmount"
                  label= {action === "wrap" ? "I want to wrap" : "I want to unwrap"}
                  max={
                    action === "wrap"
                      ? nativeTokenBalance
                      : wrapTokens[wrapTokenConfig?.address || "0x"]?.balance
                  }
                />
              </div>
            </section>
          </section>
          <section className={classes.submitButtonArea}>
            <Button type="submit" fullsize variant="primary" className={classes.wrapButton}>
              {action === "wrap" ? "Wrap CHZ" : "Unwrap CHZ"}
            </Button>
          </section>
          <section>
            <QuestionCircleSvg
              onClick={() => setAboutOpen(true)}
              className={classes.faqButton}
            />
          </section>
        </Form>
      </Formik>
      <AboutDrawer open={aboutOpen} close={() => setAboutOpen(false)} />
      <PreflightModalWrap
        open={preflightModalOpen}
        close={() => setPreflightModalOpen(false)}
        sender={address || ""}
        start={() => {
          if (action === "wrap") {
            handleWrapToken();
            setPreflightModalOpen(false);
          } else {
            handleUnwrapToken();
            setPreflightModalOpen(false);
          }
        }}
        sourceNetwork={homeConfig?.name || ""}
        tokenSymbol={
          action === "wrap"
            ? homeConfig?.nativeTokenSymbol || "ETH"
            : wrapTokenConfig?.symbol || "wETH"
        }
        value={preflightDetails?.tokenAmount || 0}
        wrappedTitle={
          action === "wrap"
            ? `${wrapTokenConfig?.name} (${wrapTokenConfig?.symbol})`
            : homeConfig?.nativeTokenSymbol || "ETH"
        }
        action={action}
      />
      {txDetails && (
        <WrapActiveModal
          {...txDetails}
          close={() => {
            setTxDetails(undefined);
            forwardTo(ROUTE_LINKS.Transfer);
          }}
        />
      )}
      {/* This is here due to requiring router */}
      {address && <NetworkUnsupportedModal wrappingDisabled={JSON.stringify(wrapTokens) === '{}'} fromWrapPage={true}/>}
    </article>
  );
};
export default MainPage;
