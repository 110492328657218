import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme";
import React from "react";
import { ReactNode } from "react";
import AppHeader from "./AppHeader";
import AppPopup from "./AppPopup";
import { AppFooter } from "./AppFooter";
import { Typography } from "../lib/common-components";
import { ChilizConfigProvider } from '../config/configProvider'
import { useLocation } from "react-router-dom";

interface IAppWrapper {
  children: ReactNode | ReactNode[];
}

const useStyles = makeStyles(
  ({ animation, constants, palette, breakpoints }: ITheme) => {
    return createStyles({
      root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'space-between',
        height: "100vh",
        paddingTop: 30,
        overflow: '-moz-scrollbars-none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
        [breakpoints.between("sm", "xl")]: {
          paddingTop: 20,
          marginTop: 55,
        },
        [breakpoints.down(300)]: {
          width: 400,
        },
      },
      title: {
        marginTop: '80px',
        fontSize: "96px",
        fontWeight: 800,
        lineHeight: "101px",
        textAlign: "center",
        color: "#610287",
        [breakpoints.down('sm')]: {
          marginTop: "50px",
          fontSize: "60px",
        }
      },
      titleFanToken: {
        marginTop: '60px',
        fontSize: "70px",
        fontWeight: 800,
        lineHeight: "71px",
        textAlign: "center",
        color: "#610287",
        [breakpoints.down('sm')]: {
          marginTop: "50px",
          fontSize: "60px",
        }
      },
      inner: {
        zIndex: 1,
        marginTop: 20,
        [breakpoints.between("xs", "sm")]: {
          marginTop: 75,
        },
        [breakpoints.down(300)]: {
          width: 400,
        },
      },
      cta: {
        display: "block",
        maxWidth: 200,
        maxHeight: 200,
        position: "fixed",
        bottom: constants.generalUnit * 3,
        right: constants.generalUnit * 3,
      },
      content: {
        // position: "absolute",
        // top: "50%",
        // left: "50%",
        // transform: "translate(-50%, -50%)",
        margin: `30px auto`,
        maxWidth: 480,
        display: "flex",
        flexDirection: "column",
        borderRadius: 4,
        padding: '0 10px 0 10px'
      },
      pageArea: {
        height: "100%",
        width: "100%",
        boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.15), 0px 8px 8px -4px rgba(16, 24, 40, 0.1)",
        borderRadius: '12px'
      },
      navTabs: {
        // position: "absolute",
        // top: 0,
        // left: 0,
        width: "100%",
        // transform: "translate(0,-100%)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: `0 ${constants.generalUnit}px`,
        transform: "translateY(1px)",
        "& > a": {
          backgroundColor: "#F5F5F5",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: `${constants.generalUnit}px ${
            constants.generalUnit * 1.5
          }px`,
          border: `1px solid ${palette.additional["gray"][7]}`,
          textDecoration: "none",
          marginRight: constants.generalUnit,
          transitionDuration: `${animation.transform}ms`,
          color: palette.additional["gray"][8],
          maxHeight: constants.navItemHeight,
          "& svg": {
            transitionDuration: `${animation.transform}ms`,
            fill: palette.additional["gray"][8],
          },
          "&.active": {
            color: palette.additional["gray"][9],
            textDecoration: "underline",
            "& svg": {
              fill: palette.additional["geekblue"][5],
            },
          },
          "& > *:first-child": {
            marginRight: constants.generalUnit,
          },
        },
        "& svg": {
          height: 14,
          width: 14,
        },
      },
    });
  }
);

const AppWrapper: React.FC<IAppWrapper> = ({ children }: IAppWrapper) => {
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;
  const config = new ChilizConfigProvider()
  return (
    <section className={classes.root}>
      <section className={classes.inner}>
        <AppHeader />
        <AppPopup />
        <Typography variant="h1" component="h1" className={ config.getBridgeDetails().Title === "Chiliz Bridge" ? classes.title : classes.titleFanToken }>
          {pathname === "/wrap" ? "Wrap Chiliz" : config.getBridgeDetails().Title}
        </Typography>
        <section className={classes.content}>
          {/* <section className={classes.navTabs}>
            <NavLink activeClassName="active" to={ROUTE_LINKS.Transfer}>
              <GlobalSvg />
              <Typography variant="h5">Transfer</Typography>
            </NavLink>
            <NavLink activeClassName="active" to={ROUTE_LINKS.Wrap}>
              <GiftSvg />
              <Typography variant="h5">Wrap token</Typography>
            </NavLink>
          </section> */}
          <div className={classes.pageArea}>{children}</div>
        </section>

        {/* Put CTA here */}
        {/* <a className={classes.cta} rel="noopener noreferrer" target="_blank" href="#">
          </a> */}
      </section>
      <AppFooter/>
    </section>
  );
};

export default AppWrapper;
