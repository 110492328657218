import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme";
import React, { useState } from "react";
import clsx from "clsx";
import { Typography } from "../lib/common-components";
import { shortenAddress } from "../Utils/Helpers";
import { useChainbridge } from "../Contexts/ChainbridgeContext";
import Logo from "../assets/bridge-logo.png";

const useStyles = makeStyles(
  ({ constants, palette, zIndex, breakpoints }: ITheme) => {
    return createStyles({
      root: {
        display: "flex",
        position: "fixed",
        justifyContent: "center",
        width: "100%",
        color: palette.additional["header"][1],
        alignItems: "center",
        zIndex: zIndex?.layer2,
        overflow: '-moz-scrollbars-none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
        [breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      },
      state: {
        display: "flex",
        flexDirection: "row",
      },
      popUp: {
        display: "block",
        position: "absolute",
        zIndex: 1,
        overflow: "scroll",
        fontSize: "18px",
        backgroundColor: "#1D0238",
        border: "0.1px solid white",
        borderRadius: "8px",
        opacity: 0.9,
      },
      popUpToggle:{
        display: "flex",
			  justifyContent: "end"
      },
      popUpContainer:{
        display: "block",
        padding: "1rem 10rem",
        marginBottom: "10px"
      },
      content: {
        color: "balck"
      },
      referenceLink: {
        marginLeft: "5px",
        color: "white",
        textDecoration: "underline",
      },
      close: {
        color: "#aaaaaa",
        float: "right",
        fontSize: "28px",
        fontWeight: "bold",
        cursor: "pointer",
        transform: "translate(-15px, 40px)",
      },
      ["@media (max-width: 768px)"]: {
        popUpContainer: {
          padding: "0.1rem 1rem"
        }
      }
    });
  }
);

interface IAppPopup {}

const AppPopup: React.FC<IAppPopup> = () => {
    const [showPopup, setPopup] = useState(true)
    const classes = useStyles();
    const { homeConfig } = useChainbridge();

    return (
        <section className={clsx(classes.root)}>
          {
            showPopup ? homeConfig?.networkId === 88880 ? (
              <div className={classes.popUp}>
                <div className={classes.popUpToggle}>
                  <span className={classes.close} onClick={() => {setPopup(false)}}>&times;</span>
                </div>
                <div className={classes.popUpContainer}>
                  <Typography variant="h5">You are using an outdated version of the bridge which would be deprecated soon...</Typography><br/>
                  <Typography variant="h5">It is recommended to use the latest version of the bridge :- 
                    <a className={classes.referenceLink} rel="noopener noreferrer" target="_blank" href="https://spicy-bridge.chiliz.com/transfer">https://spicy-bridge.chiliz.com</a>
                  </Typography>
                </div>
              </div>  
            ) : (<></>) : <></>
          }
        </section>
    );
};

export default AppPopup;
