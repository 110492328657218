import axios from 'axios';
import { ChilizConfigProvider } from '../config/configProvider'

const config = new ChilizConfigProvider()

export const fetchChilizUsdRate = async (chainId: number) => {
    try {
        const { getConfig, getDefaultChainDetails } = config;
        const chainDetails = chainId ? getConfig(chainId) : getDefaultChainDetails()

        const {data} = await axios.get(`${chainDetails.backendUrl}conversion-limits`);
        return data;
    } catch (error) {
        throw error;
    }
};