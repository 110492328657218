import React, { useState } from "react";
import { makeStyles, createStyles } from "@chainsafe/common-theme";
import { ITheme } from "@chainsafe/common-theme";
import clsx from "clsx";
import { Tooltip } from "antd";
import infoPNG from '../../assets/icons/info.png'

const useStyles = makeStyles(
  ({ typography, constants, palette, animation, zIndex, overrides }: ITheme) =>
    createStyles({
      // JSS in CSS goes here
      root: {
        ...typography.body2,
        cursor: "pointer",
        position: "relative",
        borderRadius: '8px',
        "&.large": {
          fontSize: 14,
          lineHeight: "22px",
          ...overrides?.TextInput?.size?.large,
        },
        "&.medium": {
          fontSize: 14,
          lineHeight: "22px",
          ...overrides?.TextInput?.size?.medium,
        },
        "&.small": {
          fontSize: 14,
          lineHeight: "22px",
          ...overrides?.TextInput?.size?.small,
        },
        "& .right > *:nth-child(2) svg": {
          fill: palette.primary.main,
        },
        "& label": {
          display: "block",
          fontSize: "12px",
          lineHeight: "20px",
          marginBottom: "5px"
        },
        "& input": {
          transitionDuration: `${animation.transform}ms`,
          display: "block",
          color: "#667085",
          borderRadius: '8px',
          background: "#FFFFFF",
          border: "1px solid #D0D5DD",
          width: "100%",
          height: "44px",
          fontSize: '12px',
          lineHeight: '24px',
          padding: `${constants.generalUnit}px ${constants.generalUnit * 2}px`,
          outline: "none",
          "&.filled": {
            borderColor: "#D0D5DD !important",
          },
          "&:hover": {
            borderColor: "#D0D5DD",
            ...overrides?.TextInput?.input?.hover,
          },
          "&:focus": {
            borderColor: "#D0D5DD",
            // borderColor: palette
            boxShadow: "none",
            ...overrides?.TextInput?.input?.focus,
          },
          "&:disabled": {
            color: "#858B94",
          },
          "&:disabled:hover": {
            borderColor: "#858B94",
          },
          ...overrides?.TextInput?.input?.root,
        },
        "&.disabled": {
          "& input": {
            color: palette.additional["gray"][6],
            backgroundColor: palette.additional["gray"][3],
            ...overrides?.TextInput?.input?.disabled,
          },
          ...overrides?.TextInput?.disabled,
        },
        ...overrides?.TextInput?.root,
      },
      error: {
        "& .right > *:nth-child(2) svg": {
          fill: palette.error.main,
        },
        "& input": {
          borderColor: palette.error.border,
          "&:hover": {
            borderColor: palette.error.border,
            boxShadow: "0px 0px 4px rgba(245, 34, 45, 0.5)",
          },
          "&:focus": {
            borderColor: palette.error.border,
            boxShadow: "0px 0px 4px rgba(245, 34, 45, 0.5)",
          },
        },
      },
      warning: {
        "& .right > *:nth-child(2) svg": {
          fill: palette.warning.main,
        },
        "& input": {
          borderColor: palette.warning.border,
          "&:hover": {
            borderColor: palette.warning.border,
            boxShadow: "0px 0px 4px rgba(250, 173, 20, 0.5)",
          },
          "&:focus": {
            borderColor: palette.warning.border,
            boxShadow: "0px 0px 4px rgba(250, 173, 20, 0.5)",
          },
        },
      },
      success: {
        "& .right > *:nth-child(2) svg": {
          fill: palette.success.main,
        },
      },
      caption: {
        display: "block",
        marginTop: constants.generalUnit / 4,
        transitionDuration: `${animation.transform}ms`,
        color: palette.additional["gray"][7],
        "&.error": {
          color: palette.error.main,
        },
        "&.warning": {
          color: palette.warning.main,
        },
        "&.default": {},
        "&.minimal": {
          position: "absolute",
          bottom: `calc(100% + ${constants.generalUnit * 1.5}px)`,
          width: "max-content",
          left: "50%",
          borderRadius: 2,
          textAlign: "center",
          padding: `${constants.generalUnit}px`,
          transform: "translateX(-50%)",
          color: palette.common.white.main,
          backgroundColor: palette.common.black.main,
          ...typography.body2,
          fontWeight: typography.fontWeight.bold,
          zIndex: zIndex?.layer3,
          "&:after": {
            display: "block",
            content: "''",
            position: "absolute",
            top: "100%",
            left: "50%",
            borderWidth: constants.generalUnit,
            borderStyle: "solid",
            borderColor: ` ${palette.common.black.main} transparent transparent transparent`,
          },
          "&.error": {
            // backgroundColor: palette.error.main,
          },
          "&.warning": {
            // backgroundColor: palette.warning.main,
          },
        },
        ...overrides?.TextInput?.caption,
      },
      warningIcon: {
        fill: palette.warning.main,
        "& svg": {
          fill: `${palette.warning.main} !important`,
        },
      },
      errorIcon: {
        fill: palette.error.main,
        "& svg": {
          fill: `${palette.error.main} !important`,
        },
      },
      successIcon: {
        fill: palette.success.main,
        "& svg": {
          fill: `${palette.success.main} !important`,
        }
      },
      errorMessage: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        color: '#ff0051'
      }
    })
);

export type InputState = "normal" | "warning" | "success" | "error";

export interface ITextInputProps
  extends Omit<
    React.HTMLProps<HTMLInputElement>,
    "onChange" | "label" | "size" | "value"
  > {
  className?: string;
  label?: string;
  name?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  state?: InputState;
  size?: "large" | "medium" | "small";
  onChange: (value: string | number | undefined) => void;
  type?: "text" | "email" | "password" | "url" | "search" | "number";
  autoFocus?: boolean;
  infoTooltip?: boolean;
  errorMessage?: string;
}

const TextInput = React.forwardRef(
  (
    {
      label,
      name,
      value,
      onChange,
      size = "medium",
      type = "text",
      state = "normal",
      disabled = false,
      autoFocus = false,
      infoTooltip,
      errorMessage,
      ...rest
    }: ITextInputProps,
    forwardedRef: any
  ) => {
    const classes = useStyles();
    const [focus, setFocus] = useState(false);
    const isOccupied = focus || !!(value?.toString().length !== 0);
    const inputClass = isOccupied
      ? clsx("filled", {
          disabled,
          error: state === "error",
          success: state === "success",
          warning: state === "warning",
        })
      : clsx({
          disabled,
          error: state === "error",
          success: state === "success",
          warning: state === "warning",
        });
    return (
      <div
        className={clsx(classes.root)}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
      >
        <label style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          {label} 
          {infoTooltip && (
            <Tooltip title="You can only bridge funds to the same address as the Home Network address">
              <img src={infoPNG} alt='info' width={20}/>
            </Tooltip>
          )}
        </label>
        <input
          className={inputClass}
          type={type}
          disabled={disabled}
          name={name}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          autoFocus={autoFocus}
          ref={forwardedRef}
          placeholder={label}
          {...rest}
        />
        {errorMessage && <label className={classes.errorMessage}>
          {errorMessage} 
        </label>}
      </div>
    );
  }
);

TextInput.displayName = "TextInput";

export default TextInput;
