import { createTheme } from "@chainsafe/common-theme";

export const chilizTheme = createTheme({
  globalStyling: {
    body: {
      backgroundColor: "#FBFAFC"
    },
  },
  themeConfig: {
    constants: {
      navItemHeight: 42,
    },
    palette: {
      common: {
        white: {
          main: '#FFFFFF'
        }
      },
      additional: {
        general: {
          1: "#CC1024", // Accents //geekblue4
        },
        transferUi: {
          1: "#595959", // FAQ button // gray8
        },
        header: {
          1: "#F5F5F5", // Background
          2: "#595959", // Text color //gray8
          3: "#BFBFBF", // border // gray6
        },
        preflight: {
          1: "#CC1024", // Button bg color
          2: "#EDEEF2", // Button color
        },
        transactionModal: {
          1: "#CC1024", // border //geekblue5
          2: "#F50000", // indicator border //geekblue4
          3: "#F50000", // indicator text //geekblue6
        },
      },
    },
    overrides: {
      CheckboxInput: {
        root: {
          alignItems: "center",
        },
      },
      Button: {
        size: {
          large: {
            height: '44px'
          }
        }
      },
      Typography: {
        h2: {
          color: "#434343",
        },
        h3: {
          color: "#434343",
        },
        h4: {
          color: "#434343",
        },
        body1: {
          color: "#434343",
        },
        body2: {
          color: "#434343",
        },
      },
    },
  },
});
