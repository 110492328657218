import '@rainbow-me/rainbowkit/styles.css';
import React from "react";
import { ThemeSwitcher } from "@chainsafe/common-theme";
import TagManager from '@sooro-io/react-gtm-module'
import {
  CssBaseline,
  Router,
  ToastProvider,
} from "./lib/common-components";

import Routes from "./Components/Routes";
import { chilizTheme } from "./Themes/ChilizTheme";
import { ChainbridgeProvider } from "./Contexts/ChainbridgeContext";
import AppWrapper from "./Layouts/AppWrapper";
import { NetworkManagerProvider } from "./Contexts/NetworkManagerContext";
import { chainbridgeConfig } from "./chainbridgeConfig";
import { Web3Provider } from "./lib/web3-context";
import "@chainsafe/common-theme/dist/font-faces.css";
import { UserOptions, cookie3Analytics } from '@cookie3/analytics'
import { Cookie3Provider } from './Components/Custom/Cookie3Provider'
import { wagmiConfig, chains } from "./config/wagmiConfig";

import leftBg from './assets/bg-left.png'
import rightBg from './assets/bg-right.png'
import leftBgMobile from './assets/bg-left-mobile.png'
import rightBgMobile from './assets/bg-right-mobile.png'
import { WagmiConfig } from "wagmi";
import { lightTheme, RainbowKitProvider } from "@rainbow-me/rainbowkit";

const { REACT_APP_COOKIE3_ID, REACT_APP_GTM_ID } = process.env;

const config: UserOptions = {
  siteId: Number(REACT_APP_COOKIE3_ID)
}

const analytics = cookie3Analytics(config)

// Check if REACT_APP_GTM_ID is defined and not just whitespace
if (REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: REACT_APP_GTM_ID
  }
	TagManager.initialize(tagManagerArgs)
}

const tokens = chainbridgeConfig.chains
    .filter((c) => c.type === "Ethereum")
    .reduce((tca, bc: any) => {
      if (bc.networkId) {
        return {
          ...tca,
          [bc.networkId]: bc.tokens,
        };
      } else {
        return tca;
      }
    }, {});

  const wrapTokens = chainbridgeConfig.chains
    .filter((c) => c.type === "Ethereum")
    .reduce((tca, bc: any) => {
      if (bc.networkId) {
        return {
          ...tca,
          [bc.networkId]: bc.wrapTokens,
        };
      } else {
        return tca;
      }
    }, {});

const App: React.FC<{}> = () => {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider theme={lightTheme({ accentColor: '#FF0051' })} chains={chains}>
        <ThemeSwitcher themes={{ light: chilizTheme }}>
          <CssBaseline />
          <div className="bg">
            <img className='landing-bg-image-left' alt='bg' src={leftBg} />
            <img className='landing-bg-image-right' alt='bg' src={rightBg} />
            <img className='landing-bg-image-left-mobile' alt='bg' src={leftBgMobile} />
            <img className='landing-bg-image-right-mobile' alt='bg' src={rightBgMobile} />
          </div>
          <ToastProvider autoDismiss>
            <Cookie3Provider value={analytics}>
              <Web3Provider
                tokensToWatch={tokens}
                wrapTokensToWatch={wrapTokens}
                gasPricePollingInterval={120}
                gasPriceSetting="fast"
              >
                <NetworkManagerProvider>
                  <ChainbridgeProvider>
                    <Router>
                      <AppWrapper>
                        <Routes />
                      </AppWrapper>
                    </Router>
                  </ChainbridgeProvider>
                </NetworkManagerProvider>
              </Web3Provider>
            </Cookie3Provider>
          </ToastProvider>
        </ThemeSwitcher>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default App;
