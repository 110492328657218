import React from "react";

import { makeStyles, createStyles, ITheme } from "@chainsafe/common-theme";
import { Drawer, IDrawerProps } from "../../lib/common-components";
import clsx from "clsx";

const useStyles = makeStyles(({ constants, palette }: ITheme) =>
  createStyles({
    root: {
      backgroundColor: `#FFFFFF !important`,
      color: '#434343',
      border: "none",
      padding: `${constants.generalUnit * 3}px ${constants.generalUnit * 4}px`
    },
    backdrop: {
      backgroundColor: `rgba(234, 236, 240, 0.8) !important`,
      opacity: `0.8 !important`,
    },
  })
);

interface ICustomDrawerProps extends IDrawerProps {}

const CustomDrawer: React.FC<ICustomDrawerProps> = ({
  children,
  className,
  classNames,
  position="left",
  ...rest
}: ICustomDrawerProps) => {
  const classes = useStyles();

  return (
    <Drawer
      classNames={{
        backdrop: classes.backdrop,
        ...classNames,
      }}
      className={clsx(classes.root, className)}
      position={position}
      {...rest}
    >
      {children}
    </Drawer>
  );
};

export default CustomDrawer;
