import networksConfig from './networks-config.json'
const chains = networksConfig.chainConfigs

export class ChilizConfigProvider {
  private activeChains: any[] = []
  private networks: any = {}

  constructor (config?: any[]) {
    if (config !== undefined) {      
      config.map(c => this.networks[c.networkId] = c)

      this.activeChains = config
    } else {
      chains.map(c => this.networks[c.networkId] = c)

      this.activeChains = chains
    }
  }

  public getConfig = (networkID: number) => {
    return this.networks[networkID]
  }

  public getActiveChains = () => {
    return this.activeChains
  }

  public getDefaultChainDetails = () => {
    const defaultChain = chains.filter(c => c.default)[0]
    return defaultChain
  }

  public getBridgeDetails = () => {
    return networksConfig
  }
}
