import React from "react";

import { Typography } from "../../../lib/common-components";
import { useFormikContext } from "formik";

interface IFeesFormikWrapped {
  className?: string;
  symbol?: string;
  fee?: number;
  feeSymbol?: string;
  txError?: string;
  maxTxFee: number;
  amountFormikName: string;
  chainNativeCurrencySymbol?: string;
}

const FeesFormikWrapped: React.FC<IFeesFormikWrapped> = ({
  className,
  symbol,
  fee,
  maxTxFee,
  txError,
  feeSymbol,
  amountFormikName,
  chainNativeCurrencySymbol
}: IFeesFormikWrapped) => {
  const { values } = useFormikContext();

  const totalAmount = Number((values as Record<string, any>)[amountFormikName])
  const bridgeAmount = totalAmount - (fee ?? 0)
  const totalAmountWithFees = totalAmount + maxTxFee

  return (
    <section className={className}>
      {maxTxFee !== undefined && feeSymbol !== undefined && fee !== undefined && symbol !== undefined && txError === undefined &&  (
        <>
        {
          chainNativeCurrencySymbol === 'CHZ' ?
          <div>
          <Typography component="p">Gas Fee:</Typography>
          <Typography component="p">
          ≈ {maxTxFee.toFixed(3)} {feeSymbol}
          </Typography>
          </div>
          : null
        }
        <div>
          <Typography component="p">Bridge Fee:</Typography>
          <Typography component="p">
            {`${fee.toFixed(3)} ${feeSymbol}`}
          </Typography>
        </div>
        <div>
          <Typography component="p">Bridged Amount:</Typography>
          <Typography component="p">
            {`${bridgeAmount  < 0 ? 0 : bridgeAmount.toFixed(3)} ${symbol}`}
          </Typography>
        </div>
        
        {
        chainNativeCurrencySymbol === 'CHZ' ?
        <div>
          <Typography component="p">Total Amount:</Typography>
          <Typography component="p">
          ≈ {`${totalAmountWithFees < 0 ? 0 : totalAmountWithFees.toFixed(3)} ${symbol}`}
          </Typography>
        </div>
        : null
        }
        </>
      )}
    </section>
  );
};

export default FeesFormikWrapped;
