import React, { useEffect, useState } from "react";
import { useField } from "formik";
import {
  IFormikSelectInputProps,
  Typography,
} from "../../lib/common-components";
import { SelectInput } from "./SelectInput";
import { Tokens } from "../../lib/web3-context/src/context/tokensReducer";

interface ITokenSelectInput extends IFormikSelectInputProps {
  tokens: Tokens;
  topHeaderHeight?: number;
  sync?: (value: string) => void;
}

const TokenSelectInput: React.FC<ITokenSelectInput> = ({
  className,
  label,
  name,
  tokens,
  topHeaderHeight,
  sync,
  ...rest
}: ITokenSelectInput) => {
  const [field, , helpers] = useField(name);
  const labelParsed = tokens[field.value]
    ? `${tokens[field.value]?.balance.toFixed(3)} ${tokens[field.value]?.symbol}`
    : "";

  const [synced, setSynced] = useState();
  useEffect(() => {
    if (sync && field.value !== synced) {
      setSynced(field.value);
      if (field.value !== "") {
        sync(field.value);
      }
    }
    // eslint-disable-next-line
  }, [field]);

  useEffect(() => {
    // If there is only one token, auto select
    if (Object.keys(tokens).length === 1 && field.value === "") {
      helpers.setValue(Object.keys(tokens)[0]);
    }
  }, [tokens, helpers, field.value]);

  return (
    <>
      {labelParsed !== '' && (
        <div style={{ height: `${topHeaderHeight}px`, width: '200%' }}>
        <Typography variant="body1" className="balance">
          Balance: <span style={{ color: "#FF0051"}}>{labelParsed}</span>
        </Typography>
        </div>
      )}
      <SelectInput
        name={name}
        className={className}
        label={"Token"}
        onChange={helpers.setValue}
        value={field.value}
        {...rest}
      />
    </>
  );
};

export default TokenSelectInput;
