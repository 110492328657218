import React from "react";

import { makeStyles, createStyles, ITheme } from "@chainsafe/common-theme";
import CustomModal from "../Components/Custom/CustomModal";
import {
  Typography,
} from "../lib/common-components";
import { useNetworkManager } from "../Contexts/NetworkManagerContext";
import { ChilizConfigProvider } from '../config/configProvider'

import infoPNG from "../assets/icons/info.png";
import { useSwitchNetwork } from "wagmi";

const useStyles = makeStyles(({ constants, palette, breakpoints }: ITheme) =>
  createStyles({
    root: {
      width: "100%",
      borderRadius: "10px",
      // backgroundColor: "#fff !important",
      // height: "auto",
    },
    main_container: {
      display: "flex",
      flexDirection: "column",
      // height: "auto",
      // marginBottom: "40px"
    },
    text_container: {
      display: "flex",
    },
    inner: {
      width: "100% !important",
      maxWidth: "unset !important",
      display: "flex",
      flexDirection: "row",
      padding: `${constants.generalUnit * 6}px ${constants.generalUnit * 7}px`,
      backgroundColor: `#F3F4F4 !important`,
      color: "#fff",
      overflowY: "scroll",
      "-ms-overflow-style": 'none',
      overflow: '-moz-scrollbars-none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': { display: 'none' },
    },
    heading: {
      color: "#555555",
      marginBottom: constants.generalUnit,
      marginLeft: '14% !important'
    },
    subText:{
      color: "#555",
      width: "80%",
      marginLeft: "10%",
      [breakpoints.down("sm")]: {
        marginLeft: 0,
      }
    },
    icon: {
      position: 'absolute',
      height: 30,
      width: 30,
      marginRight: constants.generalUnit * 2,
      fill: palette.additional["gray"][7],
    },
    buttons: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: constants.generalUnit * 5,
      "& > *": {
        textDecoration: "none",
        marginRight: constants.generalUnit,
      },
      width: '100% !important',
      [breakpoints.down("sm")]: {
        width: '80% !important',
      }
    },
    button: {
      padding: "10px",
      width: "170px",
      border: "1px solid #555",
      color: "#555",
      borderRadius: "10px",
      fontSize: "12px",
      fontWeight: 700,
      cursor: "pointer",
      "&:hover": {
        borderColor: palette.additional["gray"][8],
        backgroundColor: palette.additional["gray"][4],
        color: "#555",
      },
    },
    confirm_button: {
      padding: "16px 46px",
      width: "170px",
      border: "none",
      color: "#fff",
      borderRadius: "10px",
      fontSize: "16px",
      fontWeight: 700,
      cursor: "pointer",
      backgroundColor: "#ff0051",
      "&:hover": {
        backgroundColor: "#ff0040"
      }
    }
  })
);

interface NetworkUnsupportedModalProps {
  enabledAsSourceChain?: any
  wrappingDisabled?: boolean
  fromWrapPage?: boolean
}

const NetworkUnsupportedModal: React.FC<NetworkUnsupportedModalProps> = ({ enabledAsSourceChain, wrappingDisabled, fromWrapPage }) => {
  const classes = useStyles();

  const { networkSupported } = useNetworkManager();
  const { switchNetwork } = useSwitchNetwork()

  const config = new ChilizConfigProvider()
  const activeChains = config.getActiveChains()
  const supportedChainNames = activeChains
    .map<React.ReactNode>((ac, i) => <strong key={i}>{ac.name}</strong>)
    .reduce((prev, curr) => [prev, ' or ', curr])

  const connectToSupportedChainBtns = activeChains
    .map((ac, i) => {
      return ac?.enabledAsSourceChain
        ? <button className={classes.button} key={i} onClick={() => switchNetwork?.(ac.networkId)}>Connect to {ac.name}</button>
        : null
    })
  
  const connectToSupportedChainBtnsWrapPage = activeChains
  .map((ac, i) => {
    return ac?.enabledAsSourceChain && ac?.wrapTokens.length > 0
      ? <button className={classes.button} key={i} onClick={() => switchNetwork?.(ac.networkId)}>Connect to {ac.name}</button>
      : null
  })

  const supportedChainNamesWrapPage = activeChains.filter((el) => {
    return el.wrapTokens.length > 0
  })
  .map<React.ReactNode>((el) => <strong>{el.name}</strong>)
  .reduce((prev, curr) => [prev, ' or ', curr])

  return (
    <CustomModal
      className={classes.root}
      injectedClass={{
        inner: classes.inner,
      }}
      active={!networkSupported || wrappingDisabled}
    >
      <div className={classes.main_container}>
        <div className={classes.text_container}>
          <section>
            <section>
              <img src={infoPNG} className={classes.icon} alt="info logo"/> 
                <Typography className={classes.heading} variant="h3" component="h3">
                  Network Unsupported
                </Typography>
            </section>
            <section>
            <Typography className={classes.subText} component="p" variant="body1">
                We've detected that you need to switch your wallet's network to {fromWrapPage ? supportedChainNamesWrapPage : supportedChainNames} for this Dapp.
              </Typography>
              <br />
              <Typography className={classes.subText} component="p" variant="body1">
                *Some wallets may not support changing networks. If you can not change networks in your wallet you may consider switching to a different wallet.
              </Typography>
            </section>
          </section>
        </div>
        <div className={classes.buttons}>
          {fromWrapPage ? connectToSupportedChainBtnsWrapPage : connectToSupportedChainBtns}
        </div>
      </div>
    </CustomModal>
  );
};

export default NetworkUnsupportedModal;
