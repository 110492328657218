import React, { ReactNode, useState, CSSProperties } from "react";
import {
  makeStyles,
  createStyles,
  ITheme,
  useTheme,
} from "@chainsafe/common-theme";
import clsx from "clsx";
import Select, { Styles, ControlProps, IndicatorProps, PlaceholderProps, OptionProps, OptionTypeBase } from "react-select";
import { Typography } from "../../lib/common-components";

const useStyles = makeStyles(
  ({ animation, constants, palette, overrides }: ITheme) =>
    createStyles({
      root: {
        position: "relative",
        "& label": {
          display: "block",
          fontSize: "12px",
          lineHeight: "20px",
          marginBottom: "5px"
        },
        ...overrides?.SelectInput?.root,
      },
      caption: {
        display: "block",
        marginTop: constants.generalUnit / 4,
        transitionDuration: `${animation.transform}ms`,
        color: palette.additional["gray"][7],
        "&.error": {
          color: palette.error.main,
        },
        "&.warning": {
          color: palette.warning.main,
        },
        ...overrides?.SelectInput?.caption,
      }
    })
);

interface ISelectOption {
  value: string | number;
  label: string | ReactNode | number;
}

export interface ISelectInputProps {
  className?: string;
  size?: "large" | "medium" | "small";
  label?: string;
  error?: string;
  captionMessage?: string;
  placeholder?: string;
  options: ISelectOption[];
  onChange(value: any): void;
  disabled?: boolean;
  value?: any;
  isMulti?: boolean;
  isClearable?: boolean;
  name?: string;
}

export const SelectInput: React.FC<ISelectInputProps> = ({
  className,
  size = "medium",
  disabled = false,
  onChange,
  label,
  placeholder = "Please select",
  options,
  captionMessage,
  value,
  isMulti,
  name,
  isClearable = false,
}) => {
  const classes = useStyles();
  const [focus, setFocus] = useState(false);
  const { palette, animation, typography, overrides }: ITheme = useTheme();
  const isOccupied = focus || !!(value?.toString().length !== 0);
  const handleChange = (value: any) => {
    if (!disabled) {
      Array.isArray(value)
        ? onChange(value.map((v) => v.value))
        : onChange(value.value);
    }
  };
  const selectValue = Array.isArray(value)
    ? value.map((v) => options.find((o) => o.value === v))
    : options.find((o) => o.value === value);

  return (
    <div
      className={clsx(classes.root)}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <label>{label}</label>
      <Select
        options={options}
        isClearable={isClearable}
        onChange={handleChange}
        isDisabled={disabled}
        placeholder={label}
        value={selectValue}
        isMulti={isMulti}
        name={name}
        styles={{
          container: (provided: CSSProperties) => ({
            ...provided,
            height: "44px",
            outline: "none",
            borderRadius: 4,
            ...overrides?.SelectInput?.container,
          }),
          input: (provided: CSSProperties) => ({
            ...provided,
            ...overrides?.SelectInput?.control,
          }),
          control: (provided: CSSProperties, state: ControlProps<OptionTypeBase, boolean>) => ({
            ...provided,
            height: "100%",
            backgroundColor: "#FFFFFF",
            outline: "none",
            borderWidth: `1px`,
            borderColor: "#D0D5DD",
            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
            borderRadius: "8px",
            "&:hover": {
              border: `1px solid #D0D5DD`,
            },
            ...overrides?.SelectInput?.control,
          }),
          menu: (provided: CSSProperties) => ({
            ...provided,
            marginTop: "-2px",
            marginBottom: 0,
            border: "1px solid #fff",
            borderRadius: "0 0 5px 5px",
            boxShadow: 'none',
            ...overrides?.SelectInput?.menu,
          }),
          menuList: (provided: CSSProperties) => ({
            ...provided,
            backgroundColor: "#F3F4F4",
            borderRadius: "0 0 5px 5px",
          }),
          dropdownIndicator: (provided: CSSProperties, state: IndicatorProps<OptionTypeBase, boolean>) => ({
            ...provided,
            color: "#667085",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)",
            transitionProperty: "transform",
            transitionDuration: `${animation.transform * 2}ms`,
            ...overrides?.SelectInput?.dropdownIndicator,
          }),
          singleValue: (provided: CSSProperties) => ({
            ...provided,
            ...typography.body2,
            color: "#667085",
            fontSize: "12px",
            lineHeight: "24px",
            ...overrides?.SelectInput?.singleValue,
          }),
          placeholder: (provided: CSSProperties, state: PlaceholderProps<OptionTypeBase, boolean>) => ({
            ...provided,
            color: !state.isDisabled
              ? palette.additional["gray"][8]
              : palette.additional["gray"][6],
            ...overrides?.SelectInput?.placeholder,
          }),
          option: (provided: CSSProperties, state: OptionProps<OptionTypeBase, boolean>) => ({
            ...provided,
            ...typography.body2,
            backgroundColor: "#F3F4F4",
            color: "#667085",
            fontSize: "12px",
            lineHeight: "24px",
            fontWeight: state.isSelected && typography.fontWeight.bold,
            "&:hover": {
              color: "#FF0051",
            },
            ...overrides?.SelectInput?.option,
          }),
          valueContainer: (provided: CSSProperties) => ({
            ...provided,
            ...typography.body2,
            paddingTop: 0,
            paddingBottom: 0,
            fontSize: "12px",
            lineHeight: "24px",
            ...overrides?.SelectInput?.valueContainer,
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
          indicatorsContainer: (provided: CSSProperties) => ({
            ...provided,
            "& > div": {
              paddingTop: 0,
              paddingBottom: 0,
            },
            color: "#667085",
            ...overrides?.SelectInput?.indicatorsContainer,
          })
        } as Partial<Styles<OptionTypeBase, boolean>>}
        theme={(selectTheme) => ({
          ...selectTheme,
          spacing: {
            ...selectTheme.spacing,
            controlHeight: size === "large" ? 40 : size === "medium" ? 32 : 24,
          },
        })}
      />
      {captionMessage && (
        <Typography
          variant="body2"
          component="span"
          className={clsx(classes.caption)}
        >
          {captionMessage}
        </Typography>
      )}
    </div>
  );
};
