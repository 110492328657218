import {
    connectorsForWallets,
  } from '@rainbow-me/rainbowkit';
import { Chain, configureChains, createConfig } from "wagmi";
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { injectedWallet, metaMaskWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets'

import networkConfig from './networks-config.json'

const WALLET_CONNECT_PROJECT_ID = process.env.REACT_APP_WALLET_CONNECT_PROJECTID as string

const getChains = (): Chain[] => networkConfig.chainConfigs.map((chain) => {
    return {
        id: chain.networkId,
        name: chain.name,
        network: chain.name,
        nativeCurrency: chain.chainNativeCurrency,
        rpcUrls: {
            default: { http: [chain.rpcUrl] },
            public: { http: [chain.rpcUrl] }
        },
        blockExplorers: {
            default: { name: `${chain.name} Scan`, url: chain.blockExplorer },
            etherscan: { name: `${chain.name} Scan`, url: chain.blockExplorer }
        }
    }
})

const { publicClient, chains } = configureChains(getChains(), [
    jsonRpcProvider({
        rpc: chain => ({ http: chain.rpcUrls.default.http[0] })
    })
])

const connectors = connectorsForWallets([
    {
        groupName: 'Recommended',
        wallets: [
            injectedWallet({ chains, shimDisconnect: true }),
            metaMaskWallet({ chains, shimDisconnect: true, projectId: WALLET_CONNECT_PROJECT_ID }),
            walletConnectWallet({ projectId: WALLET_CONNECT_PROJECT_ID, chains })
        ]
    }
])

const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
})

export { wagmiConfig, chains, publicClient }
