import React from "react";

import { makeStyles, createStyles, ITheme } from "@chainsafe/common-theme";
import CustomDrawer from "../Components/Custom/CustomDrawer";
import { Typography } from "../lib/common-components";
import { Button } from "../Components/Custom/Button";
import { shortenAddress } from "../Utils/Helpers";
import { ReactComponent as CloseIconSvg } from "../assets/icons/Close.svg";

const useStyles = makeStyles(
  ({ constants, palette, zIndex, animation }: ITheme) =>
    createStyles({
      root: {
        zIndex: zIndex?.blocker,
        position: "absolute",
        borderRadius: "12px",
        "& li": {
          position: "relative",
          padding: `${constants.generalUnit * 0.5}px 0 ${constants.generalUnit * 0.5}px ${constants.generalUnit * 8
            }px`,
          "&:before": {
            content: "''",
            display: "block",
            backgroundColor: "#667085",
            height: constants.generalUnit,
            width: constants.generalUnit,
            borderRadius: "50%",
            position: "absolute",
            top: "50%",
            left: constants.generalUnit * 4,
            transform: "translate(-50%, -50%)",
          },
        },
      },
      header: {
        display: "flex",
        justifyContent: "space-between",
        "& svg": {
          cursor: "pointer",
          transitionDuration: `${animation.transform}ms`,
        },
        "& svg:hover": {
          opacity: 0.7,
        },
      },
      subtitle: {
        margin: `${constants.generalUnit}px 0`,
      },
      agreement: {
        margin: `${constants.generalUnit}px 0`,
      },
      buttons: {
        display: 'flex',
        justifyContent: "space-between",
        "& button": {
          height: "40px",
          width: "45%"
        }
      },
      backdrop: {
        position: "absolute",
        zIndex: zIndex?.layer4,
      },
    })
);

interface IPreflightModalTransferProps {
  open: boolean;
  close: () => void;
  sender: string;
  receiver: string;
  value: number;
  tokenSymbol: string;
  sourceNetwork: string;
  targetNetwork: string;
  start: () => void;
}

const PreflightModalTransfer: React.FC<IPreflightModalTransferProps> = ({
  open,
  close,
  receiver,
  sender,
  sourceNetwork,
  targetNetwork,
  tokenSymbol,
  value,
  start,
}: IPreflightModalTransferProps) => {
  const classes = useStyles();

  return (
    <CustomDrawer
      className={classes.root}
      classNames={{
        backdrop: classes.backdrop,
      }}
      size={430}
      open={open}
    >
      <div className={classes.header}>
        <Typography variant="h3" component="h2">
          Pre-flight check
        </Typography>
        <CloseIconSvg fill="#fff" width="32px" height="32px" onClick={close} />
      </div>
      <Typography className={classes.subtitle} variant="h5" component="p">
        Please be advised this is an experimental application:
      </Typography>
      <ul>
        <li>
          <Typography variant="h5">
            You will not be able to cancel the transaction once you submit it.
          </Typography>
        </li>
        <li>
          <Typography variant="h5">
            Your transaction could get stuck for an indefinite amount of time
          </Typography>
        </li>
        <li>
          <Typography variant="h5">
            Funds cannot be returned if they are sent to the wrong address.
          </Typography>
        </li>
        <li>
          <Typography variant="h5">
            The transaction fee may be higher than expected.
          </Typography>
        </li>
      </ul>
      <Typography className={classes.agreement} variant="h5" component="p">
        I agree and want to send{" "}
        <strong>
          {value} {tokenSymbol}
        </strong>{" "}
        from&nbsp;
        <strong>{shortenAddress(sender)}</strong> on{" "}
        <strong>{sourceNetwork}</strong> to&nbsp;
        <strong>{shortenAddress(receiver)}</strong> on{" "}
        <strong>{targetNetwork}</strong>.
      </Typography>
      <div className={classes.buttons}>
        <Button onClick={close} variant="outline">Cancel</Button>
        <Button onClick={start}>Start Transfer</Button>
      </div>
    </CustomDrawer>
  );
};

export default PreflightModalTransfer;
