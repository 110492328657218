import React from "react";

import { useField, useFormikContext } from "formik";
import TextInput from "./TextInput";
import { Button } from "./Button";
import { Tokens } from "../../lib/web3-context/src/context/tokensReducer";

interface ITokenInput {
  disabled?: boolean;
  label: string;
  name: string;
  tokens: Tokens;
  tokenSelectorKey: string;
  placeholder?: string;
  errorMessage?: (values: any) => string;
  topHeaderHeight?: number;
  maxTxFee?: number;
  classNames?: {
    input?: string;
    button?: string;
    dummyRow?: string;
  };
}

const TokenInput: React.FC<ITokenInput> = ({
  classNames,
  disabled,
  label,
  tokens,
  errorMessage,
  tokenSelectorKey,
  topHeaderHeight,
  name,
  maxTxFee = 0,
  ...rest
}: ITokenInput) => {
  const [, , helpers] = useField(name);
  const [field] = useField('token');

  const { values } = useFormikContext();
  return (
    <>
      { tokens[field.value] ? <div style={ { height: `${topHeaderHeight}px`}} />: <div/>}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <TextInput
          onChange={helpers.setValue}
          className={classNames?.input}
          disabled={disabled}
          name={name}
          label={label}
          value={(values as Record<string, any>).tokenAmount}
          type="number"
          errorMessage={errorMessage ? errorMessage(values): ''}
          {...rest}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={classNames?.dummyRow}/>
        <Button
          disabled={
            disabled || !tokens[(values as Record<string, any>)[tokenSelectorKey]]
          }
          className={classNames?.button}
          onClick={() => {
            helpers.setValue(
              tokens[(values as Record<string, any>)[tokenSelectorKey]].balance > maxTxFee ? tokens[(values as Record<string, any>)[tokenSelectorKey]].balance - maxTxFee : 0
            );
          }}
          size="small"
          variant="link"
          type="button"
        >
          Max
        </Button>
        </div>
      </div>
    </>
  );
};

export default TokenInput;
