import React from "react";
import clsx from "clsx";

import { makeStyles, createStyles, ITheme } from "@chainsafe/common-theme";
import CustomDrawer from "../Components/Custom/CustomDrawer";
import { Typography } from "../lib/common-components";
import { Button } from "../Components/Custom/Button";
import { ChilizConfigProvider } from '../config/configProvider';
import { useLocation } from "react-router-dom";

import * as DOMPurify from 'dompurify'

const useStyles = makeStyles(({ constants, zIndex }: ITheme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      maxWidth: "600px",
      width: "initial !important",
      zIndex: zIndex?.layer3,
      overflow: 'scroll'
    },
    content: {
      margin: 'auto 0',

      "& h1": {
        color: "#610287",
        fontWeight: 800,
        fontSize: '40px',
        lineHeight: '42px',
        marginBottom: '32px'
      },

      "& p": {
        fontSize: "16px",
        lineHeight: "24px",
        marginBottom: "35px"
      }
    },
    buttons: {
      marginTop: "50px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& *": {
        marginRight: constants.generalUnit,
        textDecoration: "none",
      },
      "& button": {
        flex: 0.4
      }
    },
    instructions: {
      display: "column",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 30,

      "& *": {
        textDecoration: "none",
      },

      "& p": {
        fontSize: "14px",
        lineHeight: "18px",
        marginBottom: "20px"
      },

      "& ul": {
        marginLeft: "15px",
        "& li": {
          margin: "15px 0",
          fontSize: "14px",
          lineHeight: "18px"
        }
      }
    },
    referenceLink: {
      marginLeft: '2px',
      textDecoration: "underline"
    },
    highlight: {
      color: "#FF0051"
    },
    quotes: {
      "&:before": {
        color: "#434343",
        content: "'\\0022'"
      },
      "&:after": {
        color: "#434343",
        content: "'\\0022'"
      },
    }
  })
);

interface IAboutDrawerProps {
  open: boolean;
  close: () => void;
}

const AboutDrawer: React.FC<IAboutDrawerProps> = ({
  open,
  close,
}: IAboutDrawerProps) => {
  const classes = useStyles();
  const config = new ChilizConfigProvider()
  const location = useLocation();
  const { pathname } = location;
  const instructions = pathname === "/wrap" ? config.getBridgeDetails().wrapTemplate.instructionSteps.map((item, index) => { return <li key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item) }}></li> }) : config.getBridgeDetails().helpTemplate.instructionSteps.map((item, index) => { return <li key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item) }}></li> })

  return (
    <CustomDrawer onClose={close} open={open} className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h1" component="h1">
          {pathname === "/wrap" ? config.getBridgeDetails().wrapTemplate.title : config.getBridgeDetails().helpTemplate.title}
        </Typography>
        <Typography component="p" variant="h5">
           {pathname === "/wrap" ? config.getBridgeDetails().wrapTemplate.description : config.getBridgeDetails().helpTemplate.description}
        </Typography>
        <section className={classes.instructions}>
          <Typography component="p">{pathname === "/wrap" ? config.getBridgeDetails().wrapTemplate.instructionTitle : config.getBridgeDetails().helpTemplate.instructionTitle}</Typography>
          <ul style={{ listStyle: "disc" }}>{instructions}</ul>
        </section>
          <Typography component="p">
            {pathname === "/wrap" ? config.getBridgeDetails().wrapTemplate.support : config.getBridgeDetails().helpTemplate.support}
            <a className={clsx(classes.referenceLink, classes.highlight)} rel="noopener noreferrer" target="_blank" href={pathname === "/wrap" ? config.getBridgeDetails().wrapTemplate.supportLink : config.getBridgeDetails().helpTemplate.supportLink}>{pathname === "/wrap" ? config.getBridgeDetails().wrapTemplate.supportLink : config.getBridgeDetails().helpTemplate.supportLink}</a>
            <br />
          </Typography>
        <section className={classes.buttons}>
          <Button onClick={() => close()} variant="primary" size="large">
            OK
          </Button>
          {/* <a
            rel="noopener noreferrer"
            href={process.env.REACT_APP_SUPPORT_URL}
            target="_blank"
          >
            <Button variant="outline">
              Ask a question on {process.env.REACT_APP_SUPPORT_SERVICE}
            </Button>
          </a> */}
        </section>
      </div>
    </CustomDrawer>
  );
};

export default AboutDrawer;
