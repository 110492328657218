import { ChilizConfigProvider } from './config/configProvider'

const config: ChilizConfigProvider = new ChilizConfigProvider()

export type TokenConfig = {
  address: string;
  name?: string;
  symbol?: string;
  imageUri?: string;
  resourceId: string;
  decimals: number,
  isNativeWrappedToken?: boolean;
};

export type ChainType = "Ethereum";
export interface IExternalLink {
  name: string
  url: string
}

export type BridgeConfig = {
  networkId?: number;
  chainId: number;
  bridgeAddress: string;
  name: string;
  rpcUrl: string;
  type?: string;
  enabledAsSourceChain?: boolean;
  tokens: TokenConfig[];
  wrapTokens: TokenConfig[];
  nativeTokenSymbol: string;
  chainNativeCurrency: {
    symbol: string
  };
  isMainnet: boolean;
  backendUrl: string;
  externalLinks?: IExternalLink[]
  default: boolean
};

export type EvmBridgeConfig = BridgeConfig & {
  bridgeAddress: string;
  erc20HandlerAddress: string;
  //This should be the full path to display a tx hash, without the trailing slash, ie. https://etherscan.io/tx
  blockExplorer?: string;
  defaultGasPrice?: number;
  deployedBlockNumber?: number;
};

export type ChainbridgeConfig = {
  chains: Array<EvmBridgeConfig>;
};

export const chainbridgeConfig: ChainbridgeConfig = { chains: config.getActiveChains() };

