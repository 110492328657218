import { ChilizConfigProvider } from '../../config/configProvider'
import   { Dropdown, Space, MenuProps }  from 'antd'
import React from 'react'
import { ReactComponent as ChevronDown } from '../../assets/icons/ChevronDown.svg'
import { IExternalLink } from '../../chainbridgeConfig'

import './dropdown.css';

const getExternalLinkMenuItems = (externalLinks?: IExternalLink[]): any[] => {
  if (typeof externalLinks === 'undefined') {
    return []
  }
  const menuItems: any[] = []
  for (const externalLink of externalLinks) {
    const { name, url } = externalLink
    menuItems.push({
      label: (
        <a target="_blank" rel="noopener noreferrer" style={{"textDecoration": "none"}} href={url}>
          {name}
        </a>
      ),
      key: `external-${menuItems.length}`
    })
  }
  return menuItems
}

const CustomDropDown = ({ style, itemstyle, chainId }: any) => {
  const config = new ChilizConfigProvider()
  let chainDetails:any
  
  if(chainId === 0 || undefined) 
    chainDetails = config.getDefaultChainDetails()

  if(chainId && chainId !== 0)
    chainDetails = config.getConfig(chainId)
  
  const items: MenuProps['items'] = getExternalLinkMenuItems(chainDetails.externalLinks)

  return (
    <Dropdown menu={{ items }} className="dropdown" trigger={['click']} overlayStyle={style} overlayClassName={itemstyle}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          Explore
          <ChevronDown />
        </Space>
      </a>
    </Dropdown>
  )
}

export default CustomDropDown;