import React, { useEffect, useState, useCallback } from "react";
import { makeStyles, createStyles, ITheme } from "@chainsafe/common-theme";
import AboutDrawer from "../../Modules/AboutDrawer";
import ChangeNetworkDrawer from "../../Modules/ChangeNetworkDrawer";
import PreflightModalTransfer from "../../Modules/PreflightModalTransfer";
import { Typography, QuestionCircleSvg } from "../../lib/common-components";
import { Form, Formik } from "formik";
import { Button } from "../Custom/Button";
import AddressInput from "../Custom/AddressInput";
import { SelectInput } from "../Custom/SelectInput";
import clsx from "clsx";
import TransferActiveModal from "../../Modules/TransferActiveModal";
import { useChainbridge } from "../../Contexts/ChainbridgeContext";
import TokenSelectInput from "../Custom/TokenSelectInput";
import TokenInput from "../Custom/TokenInput";
import { object, string } from "yup";
import { utils } from "ethers";
import FeesFormikWrapped from "./FormikContextElements/Fees";
import { useNetworkManager } from "../../Contexts/NetworkManagerContext";
import NetworkUnsupportedModal from "../../Modules/NetworkUnsupportedModal";
import { useCookie3 } from '../Custom/Cookie3Provider'
import { fetchChilizUsdRate } from "../../api/fetchChilizUsdRate"
import { useDynamicSetting } from "../../hooks/useDynamicSetting";
import { useChainModal } from "@rainbow-me/rainbowkit";
import { ConnectButton } from "../Custom/ConnectButton";

const useStyles = makeStyles(({ constants }: ITheme) =>
  createStyles({
    root: {
      padding: "24px",
      position: "relative",
      borderRadius: "12px",
      backgroundColor: "#FFFFFF",
      overflowY: "scroll",
      "-ms-overflow-style": 'none',
      overflow: '-moz-scrollbars-none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': { display: 'none' },
    },
    walletArea: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    connectButton: {
      margin: `${constants.generalUnit * 3}px 0 ${constants.generalUnit * 6}px`,
      textTransform: 'none',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px'
    },
    submitButton: {
      textTransform: 'none',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px'
    },
    connecting: {
      textAlign: "center",
      marginBottom: constants.generalUnit * 2,
    },
    connected: {
      width: '100%',
      "& p": {
        fontSize: "12px",
        lineHeight: "20px",
        marginBottom: "5px"
      }
    },
    homeNetwork: {
      border: "1px solid #D0D5DD",
      borderRadius: "8px",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 14px",
      marginBottom: "24px"
    },
    networkName: {
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "24px",
      height: "22px"
    },
    changeButton: {
      cursor: "pointer",
      color: "#FF0051",
      textDecoration: "underline",
      fontSize: "10px"
    },
    formArea: {
      "&.disabled": {
        opacity: 0.4,
      },
    },
    currencySection: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
      margin: `${constants.generalUnit * 3}px 0`,
      height: "130px",
    },
    tokenInputArea: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
    },
    tokenInputSection: {
      width: "55%",
      height: "100%",
    },
    dummyRow: {
      height: "20px",
      marginBottom: "5px"
    },
    tokenInput: {
      margin: 0,
      "& > div": {
        height: 32,
        "& input": {
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          borderRight: 0,
        },
      },
      "& span:last-child.error": {
        position: "absolute",
      },
    },
    maxButton: {
      height: "44px",
      alignSelf: "flex-end",
      textTransform: 'none',
      fontWeight: 600,
      left: -1,
    },
    currencySelector: {
      width: "45%",
      paddingRight: constants.generalUnit,
      "& *": {
        cursor: "pointer",
      },
      height: "100%"
    },
    token: {},
    address: {
      margin: 0,
      marginBottom: constants.generalUnit * 3,
    },
    addressInput: {},
    generalInput: {
      "& > span": {
        marginBottom: constants.generalUnit,
      },
    },
    faqButton: {
      cursor: "pointer",
      height: 21,
      width: 21,
      marginTop: constants.generalUnit * 5,
      fill: `#FF0051 !important`,
      background: "#EDEDED",
      borderRadius: "50%"
    },
    heading: {
      marginTop: constants.generalUnit,
      marginBottom: constants.generalUnit,
      color: "orange",
      textAlign: "center"
    },
    tokenItem: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
      "& img, & svg": {
        display: "block",
        height: 14,
        width: 14,
        marginRight: 10,
      },
      "& span": {
        minWidth: `calc(100% - 20px)`,
        textAlign: "left",
      },
    },
    fees: {
      display: "flex",
      flexDirection: "column",
      marginBottom: constants.generalUnit,
      "& div": {
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
        margin: "15px 0",
        "& p": {
          color: "#667085",
          fontWeight: 700,
          fontSize: "12px",
          lineHeight: "13px",
        }
      },
    },
    accountSelector: {
      marginBottom: 24,
    },
    helpSection: {
      gap: 5,
      display: "flex",
      justifyContent: "space-start",
      alignItems: "flex-end",
      cursor: "pointer",
    },
    helpTextItem: {
      fontSize: 16,
      color: "#858080 !important"
    }
  })
);

type PreflightDetails = {
  tokenAmount: number;
  token: string;
  tokenSymbol: string;
  receiver: string;
};

const TransferPage = () => {
  const classes = useStyles();
  const { walletType } = useNetworkManager();

  const { openChainModal } = useChainModal()

  const cookie3 = useCookie3()
  useEffect(() => {
    void cookie3?.trackPageView({documentTitle: 'Transfer'})
  }, [cookie3])

  const {
    deposit,
    depositEstimationFee,
    setDestinationChain,
    transactionStatus,
    resetDeposit,
    bridgeFee,
    tokens,
    isReady,
    homeConfig,
    destinationChainConfig,
    destinationChains,
    address,
    bridgePaused,
  } = useChainbridge();
  
  const [aboutOpen, setAboutOpen] = useState<boolean>(false);
  const [walletConnecting, setWalletConnecting] = useState(false);
  const [preflightModalOpen, setPreflightModalOpen] = useState<boolean>(false);
  const [maxTxFee, setMaxTxFee] = useState<number>(0);
  const [txError, setTxError] = useState<string>();
  const [minAmountOfChzToTx, setMinAmountOfChzToTx] = useState<number>(0);
  const [preflightDetails, setPreflightDetails] = useState<PreflightDetails>({
    receiver: "",
    token: "",
    tokenAmount: 0,
    tokenSymbol: "",
  });

  const networkId = homeConfig?.networkId ?? 0
  const isOnChilizChain = homeConfig?.chainNativeCurrency?.symbol === 'CHZ';

  const maxTxFeeSecurityFactor = useDynamicSetting({ chainId: networkId, key: 'TX_GAS_FEE_MULTIPLIER', defaultValue: 1 });

  useEffect(() => {
    if (walletType !== "select" && walletConnecting === true) {
      setWalletConnecting(false);
    } else if (walletType === "select") {
      setWalletConnecting(true);
    }
  }, [walletType, walletConnecting]);

  useEffect(() => { 
    const calculateMaxTxFee = async () => {
      if (address && preflightDetails.token) {
        const getMaxTxFee = await depositEstimationFee(
          address,
          preflightDetails.token
        );

        if (!getMaxTxFee) {
          setTxError('Not enough funds');
          return;
        }

        const maxTxFeeWithSecurityFactor = Number(getMaxTxFee) * (Number(maxTxFeeSecurityFactor) || Number(process.env.REACT_APP_MAX_TX_FEE_SECURITY_FACTOR) );
        getMaxTxFee && setMaxTxFee(maxTxFeeWithSecurityFactor);
        setTxError(undefined);
      }
    };
  
    isOnChilizChain && calculateMaxTxFee();
  }, [bridgeFee, depositEstimationFee, address, preflightDetails.tokenAmount, preflightDetails.token, maxTxFee, networkId, maxTxFeeSecurityFactor, isOnChilizChain]);
  
  const DECIMALS =
    preflightDetails && tokens[preflightDetails.token]
      ? tokens[preflightDetails.token].decimals
      : 18;

  const REGEX =
    DECIMALS > 0
      ? new RegExp(`^[0-9]{1,18}(.[0-9]{1,${DECIMALS}})?$`)
      : new RegExp(`^[0-9]{1,18}?$`);

  const transferSchema = object().shape({
    tokenAmount: string()
      .test("Token selected", "Please select a token", (value) => {
        if (
          !!value &&
          preflightDetails &&
          tokens[preflightDetails.token] &&
          tokens[preflightDetails.token].balance !== undefined
        ) {
          return true;
        } else {
          return false;
        }
      })
      .test("InputValid", "Input invalid", (value) => {
        try {
          return REGEX.test(`${value}`);
        } catch (error) {
          console.error(error);
          return false;
        }
      })
      .test("Max", "Insufficent funds", (value) => {
        if (
          value &&
          preflightDetails &&
          tokens[preflightDetails.token] &&
          tokens[preflightDetails.token].balance
        ) {
          if (homeConfig?.type === "Ethereum") {
            return parseFloat(value) <= tokens[preflightDetails.token].balance;
          } else {
            return (
              parseFloat(value + (bridgeFee ?? 0)) <=
              tokens[preflightDetails.token].balance
            );
          }
        }
        return false;
      })
      .test("Min", "Less than minimum", (value) => {
        if (value) {
          return parseFloat(value) > 0;
        }
        return false;
      })
      .required("Please set a value"),
    token: string().required("Please select a token"),
    receiver: string()
      .test("Valid address", "Please add a valid address", (value) => {
        return utils.isAddress(value as string);
      })
      .required("Please add a receiving address"),
  });

  const getMinAmountOfCHZtoBridge = useCallback(async() => {
    try {
      const response = await fetchChilizUsdRate(networkId);

      const conversionRates = response.limits.chz
      return conversionRates
    } catch {
      // Fallback to hardcoded the min. amount of chiliz to bridge on pesimistic scenario (0.01 USD per CHZ and 50 USD minimum transaction)
      const MIN_USD_TX = 25;
      const ESTIMATED_CHZ_PRICE = 0.05;
      return (MIN_USD_TX / ESTIMATED_CHZ_PRICE);
    }
  },[networkId])

  const isAmountToBridgeLowerThanMin = useCallback((tokenAmount: string | number) => {
    const tokenAmountFloat = Number(tokenAmount);
    if (tokenAmountFloat < minAmountOfChzToTx) {
      return true;
    }
    return false;
  }, [minAmountOfChzToTx]);

  const checkIfAmountToBridgeIsLowerThanMinOnChzChain = useCallback((tokenAmount: string | number) => isOnChilizChain && isAmountToBridgeLowerThanMin(tokenAmount), [isAmountToBridgeLowerThanMin, isOnChilizChain])

  useEffect(() => {
    const fetchData = async () => {
      const usdToChzRate = await getMinAmountOfCHZtoBridge();
      setMinAmountOfChzToTx(usdToChzRate);
    }
    fetchData();
  },[setMinAmountOfChzToTx, getMinAmountOfCHZtoBridge]);

  return (
    <article className={classes.root}>
      <div className={classes.walletArea}>
        {!isReady ? (
          <ConnectButton size="large" injectedClasses={{ connect: classes.connectButton }} fullsize />
        ) : (
          <section className={classes.connected}>
            <Typography component="p">Home Network</Typography>
            <div className={classes.homeNetwork}>

              <Typography className={classes.networkName}>{homeConfig?.name}</Typography>
              {!homeConfig?.enabledAsSourceChain || destinationChainConfig?.enabledAsSourceChain && <Typography className={classes.changeButton} onClick={() => openChainModal?.()}>Change Network</Typography>}
            </div>
          </section>
        )}
      </div>
      <Formik
        initialValues={{
          tokenAmount: 0,
          token: "",
          receiver: "",
        }}
        validateOnChange={false}
        validationSchema={transferSchema}
        onSubmit={async(values) => {
          if (bridgePaused) return; // Failsafe.
          if (isOnChilizChain) {
            // If on Chz chain and amount < minAmount, then Failsafe.
            const minAmountOfCHZToBridge = await getMinAmountOfCHZtoBridge()
            if (values.tokenAmount < minAmountOfCHZToBridge) return;
          }
          
          setPreflightDetails({
            ...values,
            tokenSymbol: tokens[values.token].symbol || "",
          });
          setPreflightModalOpen(true);
        }}
      >
        {({ values }) => (
        <Form
          className={clsx(classes.formArea, {
            disabled: !homeConfig || !address,
          })}
        >
          <section>
            <SelectInput
              label="Destination Network"
              className={classes.generalInput}
              disabled={!homeConfig}
              options={destinationChains.map((dc) => ({
                label: dc.name,
                value: dc.chainId,
              }))}
              onChange={(value) => setDestinationChain(value)}
              value={destinationChainConfig?.chainId}
            />
          </section>
          <section className={classes.currencySection}>
            {/* <section>
              <div
                className={clsx(classes.tokenInputArea, classes.generalInput)}
              >
                <TokenInput
                  classNames={{
                    input: clsx(classes.tokenInput, classes.generalInput),
                    button: classes.maxButton,
                  }}
                  tokenSelectorKey="token"
                  tokens={tokens}
                  disabled={
                    !destinationChainConfig ||
                    !preflightDetails.token ||
                    preflightDetails.token === ""
                  }
                  name="tokenAmount"
                  label="I want to send"
                />
              </div>
            </section> */}
            <section className={classes.currencySelector}>
              <TokenSelectInput
                tokens={tokens}
                name="token"
                disabled={!destinationChainConfig}
                label={`Balance: `}
                className={classes.generalInput}
                placeholder=""
                topHeaderHeight={27}
                sync={(tokenAddress) => {
                  setPreflightDetails({
                    ...preflightDetails,
                    token: tokenAddress,
                    receiver: "",
                    tokenAmount: 0,
                    tokenSymbol: "",
                  });
                }}
                options={
                  Object.keys(tokens).map((t) => ({
                    value: t,
                    label: (
                      <div className={classes.tokenItem}>
                        {tokens[t]?.imageUri && (
                          <img
                            src={tokens[t]?.imageUri}
                            alt={tokens[t]?.symbol}
                          />
                        )}
                        <span>{tokens[t]?.symbol || t}</span>
                      </div>
                    ),
                  })) || []
                }
              />
            </section>
            <section className={classes.tokenInputSection}>
              <div
                className={clsx(classes.tokenInputArea, classes.generalInput)}
              >
                <TokenInput
                  classNames={{
                    input: clsx(classes.tokenInput, classes.generalInput),
                    button: classes.maxButton,
                    dummyRow: classes.dummyRow
                  }}
                  tokenSelectorKey="token"
                  tokens={tokens}
                  topHeaderHeight={27}
                  errorMessage={({tokenAmount}) =>  checkIfAmountToBridgeIsLowerThanMinOnChzChain(tokenAmount) ?
                    `Minimum amount: ${minAmountOfChzToTx} CHZ` : (txError ?? '' )
                 }
                  disabled={
                    !destinationChainConfig ||
                    !preflightDetails.token ||
                    preflightDetails.token === ""
                  }
                  name="tokenAmount"
                  label="Amount"
                  maxTxFee={maxTxFee}
                />
              </div>
            </section>
          </section>
          <section>
            <AddressInput
              disabled={!destinationChainConfig}
              name="receiver"
              label="Destination Address"
              className={classes.address}
              classNames={{
                input: classes.addressInput,
              }}
              senderAddress={`${address}`}
              sendToSameAccountHelper={
                destinationChainConfig?.type === homeConfig?.type
              }
              infoTooltip={true}
            />
          </section>
          <FeesFormikWrapped
            amountFormikName="tokenAmount"
            className={classes.fees}
            fee={bridgeFee}
            maxTxFee={maxTxFee}
            feeSymbol={homeConfig?.nativeTokenSymbol}
            chainNativeCurrencySymbol={homeConfig?.chainNativeCurrency?.symbol}
            txError={txError}
            symbol={
              preflightDetails && tokens[preflightDetails.token]
                ? tokens[preflightDetails.token].symbol
                : undefined
            }
          />
          <section>
            <Button type="submit" size="large" fullsize variant="primary" disabled = {bridgePaused || checkIfAmountToBridgeIsLowerThanMinOnChzChain(values.tokenAmount) || !!txError } className={classes.submitButton}>
              Start Transfer
            </Button>
          </section>
          {
            bridgePaused && (
              <section>
                <Typography className={classes.heading} component="p" variant="body1">
                  We’re making some changes! The bridge will be back soon.
                </Typography>
              </section>
            )
          }
          <section>
            <div className={classes.helpSection} onClick={() => setAboutOpen(true)}>
              <QuestionCircleSvg className={classes.faqButton} />
            </div>
          </section>
        </Form>
        )}
      </Formik>
      <AboutDrawer open={aboutOpen} close={() => setAboutOpen(false)} />
      <PreflightModalTransfer
        open={preflightModalOpen}
        close={() => setPreflightModalOpen(false)}
        receiver={preflightDetails?.receiver || ""}
        sender={address || ""}
        start={() => {
          setPreflightModalOpen(false);
          preflightDetails &&
            deposit(
              preflightDetails.tokenAmount - (bridgeFee ?? 0),
              preflightDetails.receiver,
              preflightDetails.token
            );
        }}
        sourceNetwork={homeConfig?.name || ""}
        targetNetwork={destinationChainConfig?.name || ""}
        tokenSymbol={preflightDetails?.tokenSymbol || ""}
        value={preflightDetails?.tokenAmount || 0}
      />
      <TransferActiveModal open={!!transactionStatus} close={resetDeposit} />
      {/* This is here due to requiring router */}
      <NetworkUnsupportedModal enabledAsSourceChain={homeConfig} />
    </article>
  );
};
export default TransferPage;
