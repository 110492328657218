import React, {useState} from "react";

import { makeStyles, createStyles, ITheme } from "@chainsafe/common-theme";
import {
  ExclamationCircleSvg,
  ProgressBar,
  Typography,
} from "../lib/common-components";
import { Button } from "../Components/Custom/Button";
import CustomModal from "../Components/Custom/CustomModal";
import { useChainbridge } from "../Contexts/ChainbridgeContext";
import { EvmBridgeConfig } from "../chainbridgeConfig";
import AboutDrawer from "./AboutDrawer";

import {ReactComponent as CloseSVG} from '../assets/icons/Close.svg';

const useStyles = makeStyles(
  ({ animation, constants, palette, typography }: ITheme) =>
    createStyles({
      root: {
        width: "100%",
        borderRadius: "12px",
      },
      inner: {
        overflowY: "scroll",
        "-ms-overflow-style": 'none',
        overflow: '-moz-scrollbars-none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
        width: "100% !important",
        maxWidth: "unset !important",
        display: "flex",
        flexDirection: "column",
        padding: `${constants.generalUnit * 2}px ${
          constants.generalUnit * 3.5
        }px`,
        bottom: 0,
        top: "unset",
        transform: "unset",
        left: 0,
        border: "none",
        borderRadius: 0,
        transitionDuration: `${animation.transform}ms`,
        backgroundColor: "#fff",
      },
      close: {
        width: "100%",
        display: "flex",
        justifyContent: "end",
        alignItems: "end",
        cursor: "pointer",
        "& svg": {
          fill: "#ff0051"
        }
      },
      heading: {
        marginBottom: constants.generalUnit,
        whiteSpace: "nowrap",
        color: "#555"
      },
      stepIndicator: {
        ...typography.h4,
        height: 40,
        width: 40,
        marginRight: constants.generalUnit * 2,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: `1px solid #ff0051`,
        color: "#ff0051",
        "& svg": {
          height: 20,
          width: 20,
          display: "block",
          fill: "#ff0051",
        },
      },
      content: {
        display: "flex",
        flexDirection: "column",
      },
      buttons: {
        display: "inline-grid",
        gridTemplateColumns: "1fr 1fr",
        marginTop: constants.generalUnit * 5,
        "& > *": {
          textDecoration: "none",
          marginRight: constants.generalUnit,
        },
      },
      helpButton: {
        width: "100%",
        height: "100%"
      },
      initCopy: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        color: "#555",
        "& > *:first-child": {
          marginTop: constants.generalUnit * 3.5,
          marginBottom: constants.generalUnit * 8,
        },
        overflowY: "scroll",
        "-ms-overflow-style": 'none',
        overflow: '-moz-scrollbars-none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
      },
      sendingCopy: {},
      vote: {
        display: "flex",
        flexDirection: "row",
        marginTop: constants.generalUnit,
        color: "#555",
        "& > *": {
          "&:first-child": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: 240,
          },
          "&:last-child": {
            marginLeft: constants.generalUnit * 3.5,
            fontStyle: "italic",
          },
        },
      },
      warning: {
        marginTop: constants.generalUnit * 3.5,
        display: "block",
        color: "#555",
        fontWeight: 600,
      },
      receipt: {
        marginTop: constants.generalUnit * 3.5,
        marginBottom: constants.generalUnit * 8,
        color: "#555"
      },
      weighted: {
        fontWeight: 600,
      },
      progress: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        "& > *": {
          borderRadius: "0 !important",
          "&  >  *:first-child": {
            background: "#1A1F32 !important",
            opacity: 0.9,
          },
          "&  >  *": {
            borderRadius: "0 !important",
            background: `${palette.additional["transactionModal"][1]} !important`,
          },
        },
        "& > *.primary": {
          background: `${palette.additional["transactionModal"][1]} !important`,
        },
      },
      
    })
);

interface ITransferActiveModalProps {
  open: boolean;
  close: () => void;
}

const TransferActiveModal: React.FC<ITransferActiveModalProps> = ({
  open,
  close,
}: ITransferActiveModalProps) => {
  const classes = useStyles();
  const {
    depositNonce,
    transactionStatus,
    depositVotes,
    inTransitMessages,
    homeConfig,
    destinationChainConfig,
    depositAmount,
    transferTxHash,
    selectedToken,
    tokens,
  } = useChainbridge();

  const [aboutOpen, setAboutOpen] = useState<boolean>(false);
  const tokenSymbol = selectedToken && tokens[selectedToken]?.symbol;
  return (
    <CustomModal
      className={classes.root}
      injectedClass={{
        inner: classes.inner,
      }}
      active={open}
    >
      {/* <ProgressBar
        className={classes.progress}
        size="small"
        variant="primary"
        progress={transactionStatus !== "Transfer Completed" ? -1 : 100}
      /> */}
      <div className={classes.close}><CloseSVG onClick={() => {close()}}/></div>
      <section style={{display: "flex", flexDirection: "row"}}>
        <div className={classes.stepIndicator}>
          {transactionStatus === "Initializing Transfer" ? (
            "1"
          ) : transactionStatus === "In Transit" ? (
            "2"
          ) : transactionStatus === "Transfer Completed" ? (
            "3"
          ) : (
            <ExclamationCircleSvg />
          )}
        </div>
      <section className={classes.content}>
        <Typography className={classes.heading} variant="h3" component="h3">
          {transactionStatus === "Initializing Transfer"
            ? "Initializing Transfer"
            : transactionStatus === "In Transit"
            ? `In Transit`
            : transactionStatus === "Transfer Completed"
            ? "Transfer completed"
            : "Transfer aborted"}
        </Typography>
        {transactionStatus === "Initializing Transfer" ? (
          <div className={classes.initCopy}>
            <Typography>Deposit pending...</Typography>
            <Typography className={classes.weighted}>
              This should take a few minutes.
              <br />
              Please do not refresh or leave the page.
            </Typography>
          </div>
        ) : transactionStatus === "In Transit" ? (
          <div className={classes.sendingCopy}>
            {inTransitMessages.map((m, i) => {
              if (typeof m === "string") {
                return (
                  <Typography className={classes.vote} component="p" key={i}>
                    {m}
                  </Typography>
                );
              } else {
                return (
                  <Typography className={classes.vote} component="p" key={i}>
                    <span>Vote casted by {m.address}</span>
                    <span>{m.signed}</span>
                  </Typography>
                );
              }
            })}
            <Typography className={classes.warning}>
              Your transfer has started! <br />
              This can take up to 30 minutes.. <br />
              {
                depositNonce && (`This is your deposit nonce ${depositNonce}.`)
              }
            </Typography>
          </div>
        ) : transactionStatus === "Transfer Completed" ? (
          <>
            <Typography className={classes.receipt} component="p">
              Successfully transferred{" "}
              <strong>
                {depositAmount} {tokenSymbol}
                <br /> from {homeConfig?.name} to {destinationChainConfig?.name}
                .
              </strong>
            </Typography>
            <section className={classes.buttons}>
              <Button
                onClick={() =>
                  destinationChainConfig &&
                  (destinationChainConfig as EvmBridgeConfig).blockExplorer &&
                  transferTxHash &&
                  window.open(
                    `${
                      (destinationChainConfig as EvmBridgeConfig).blockExplorer
                    }tx/${transferTxHash}`,
                    "_blank"
                  )
                }
                variant="outline"
                // disabled={
                //   !destinationChain ||
                //   !destinationChain.blockExplorer ||
                //   !transferTxHash
                // }
              >
                View transaction
              </Button>
              <Button variant="outline" onClick={close}>
                Start new transfer
              </Button>
            </section>
          </>
        ) : (
          <>
            <Typography className={classes.receipt} component="p">
              Something went wrong and we could not complete your transfer.
            </Typography>
            {homeConfig &&
              (homeConfig as EvmBridgeConfig).blockExplorer &&
              transferTxHash && (
                <Button
                  onClick={() =>
                    window.open(
                      `${
                        (homeConfig as EvmBridgeConfig).blockExplorer
                      }tx/${transferTxHash}`,
                      "_blank"
                    )
                  }
                  variant="outline"
                  disabled
                >
                  View transaction
                </Button>
              )}
            <section className={classes.buttons}>
              <Button variant="outline" onClick={close}>
                Start new transfer
              </Button>
              <Button variant="outline" 
                className={classes.helpButton}
                onClick={() => setAboutOpen(true)}
              >
                Help
              </Button>
            </section>
          </>
        )}
      </section>
      </section>
      <AboutDrawer open={aboutOpen} close={() => setAboutOpen(false)} />
    </CustomModal>
  );
};

export default TransferActiveModal;
